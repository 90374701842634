import {login, getUserInfo} from '@/api/index'

import {getToken, setToken, removeToken} from '@/utils/auth'

const state = {
    openEnv: false, // 打开环境
    walletAdd: '', // 获取钱包地址
    token: getToken(),
    userInfo: null,//用户信息
}

const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_WALLETADD: (state, walletAdd) => {
        state.walletAdd = walletAdd
    },

    SET_OPENENV: (state, openEnv) => {
        state.openEnv = openEnv
    },
    SET_USERINFO: (state, userInfo) => {
        state.userInfo = userInfo
    }
}

const actions = {
    login({commit}, userInfo) {
        return new Promise((resolve, reject) => {
            login(userInfo).then(response => {
                const {data} = response
                console.log(data);
                if (data.code == 200) {
                    console.log('token', data.data.token);
                    setToken(data.data.token)
                    commit('SET_TOKEN', data.data.token)
                }
                resolve(data)
            }).catch(error => {
                reject(error)
            })
        })
    },
    logOut({commit}) {
        removeToken()
        sessionStorage.removeItem('invite_code')
        commit('SET_WALLETADD', '--')
        window.location.reload()
    },
    getUserInfo({commit}) {
        return new Promise((resolve, reject) => {
            getUserInfo().then(response => {
                const {data} = response
                if (data.code == 200) {
                    commit('SET_USERINFO', data.data)
                } else {
                    removeToken()
                    window.location.reload()
                }
                resolve(data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    setWalletAdd: ({commit}, walletAdd) => {
        commit('SET_WALLETADD', walletAdd)
    },
    setToken: ({commit}, token) => {
        commit('SET_TOKEN', token)
    },
    setOpenenv({commit}, openEnv) {
        commit('SET_OPENENV', openEnv)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
