// zh_CN 简体中文
module.exports = {
    "敬请期待": "敬请期待",
    "加载中": "加载中",
    "没有更多了": "没有更多了",
    "钱包地址": "钱包地址",
    "首页": "首页",
    "银行": "银行",
    "数字人": "数字人",
    "游戏": "游戏",
    "邀请": "邀请",
    "套餐": "套餐",
    "收益": "收益",
    "社区": "社区",
    "个人中心": "个人中心",
    "银行个人中心": "银行个人中心",
    "数字人个人中心": "数字人个人中心",
    "退出登录": "退出登录",
    "请绑定钱包": "请绑定钱包",
    "娱乐": "娱乐",
    "生态": "生态",
    "个人资产": "个人资产",
    "总资产": "总资产",
    "开户": "开户",
    "元交易": "元交易",
    "元储存": "元储存",
    "数据契约": "数据契约",
    "开户成功": "开户成功",
    "我的邀请人": "我的邀请人",
    "确定": "确定",
    "绑定成功": "绑定成功",
    "请输入正确邀请码": "请输入正确邀请码",
    "银行提示": "温馨提示：贝灵银行邀请链接只限于贝灵银行使用， 贝灵王国各个应用会有独立邀请链接。",
    "闪兑": "闪兑",
    "兑换的币种直接进入平台个人中心": "兑换的币种直接进入平台个人中心",
    "兑换": "兑换",
    "兑出币种": "兑出币种",
    "可用": "可用",
    "全部": "全部",
    "兑入币种": "兑入币种",
    "请输入正确的数量": "请输入正确的数量",
    "请输入": "请输入",
    "我的资产": "我的资产",
    "种草值": "种草值",
    "养草值": "养草值",
    "拔草值": "拔草值",
    "特奖值": "特奖值",
    "充值": "充值",
    "提出": "提出",
    "提出成功": "提出成功",
    "提出记录": "提出记录",
    "购买记录": "购买记录",
    "购买": "购买",
    "手续费": "手续费",
    "日期": "日期",
    "转入地址": "转入地址",
    "充值币种": "充值币种",
    "充值成功": "充值成功",
    "充值记录": "充值记录",
    "我的邀请码": "我的邀请码",
    "邀请码": "邀请码",
    "生成图片": "生成图片",
    "复制链接": "复制链接",
    "复制成功": "复制成功",
    "扫一扫": "扫一扫 了解",
    "消息中心": "公告",
    "查看详情": "查看详情",
    "数字人提示": "温馨提示：贝灵数字人邀请链接只限于贝灵数字人使用， 贝灵王国各个应用会有独立邀请链接。",
    "激活": "激活",
    "我的": "我的",
    "已使用": "已使用",
    "未使用": "未使用",
    "编号": "编号",
    "激活成功": "激活成功",
    "请输入正确的CDK": "请输入正确的CDK",
    "记录": "记录",
    "已完成": "已完成",
    "未完成": "未完成",
    "失败": "失败",
    "审核中": "审核中",
    "兑换套餐": "兑换套餐",
    "我的业绩": "我的业绩",
    "社区业绩": "社区业绩",
    "大区业绩": "大区业绩",
    "小区总业绩": "小区总业绩",
    "直推": "直推",
    "对碰": "对碰",
    "搜索": "搜索",
    "搜索UID编号": "搜索UID编号",
    "历史搜索": "历史搜索",
    "下级": "下级",
    "兑换成功": "兑换成功",
    "闪兑记录": "闪兑记录",
    "交易服务费": "交易服务费",
    "总养草值": "总养草值",
    "已释放养草值": "已释放养草值",
    "未释放养草值": "未释放养草值",
    "已释放特奖值": "已释放特奖值",
    "未释放特奖值": "未释放特奖值",
    "静态": "静态",
    "动态": "动态",
    "特殊": "特殊",
    "详细记录": "详细记录",
    "近一周": "近一周",
    "近一个月": "近一个月",
    "近三个月": "近三个月",
    "近一年": "近一年",
    "加入": "加入",
    "复投": "复投",
    "欢迎获得西欧特权": "欢迎获得西欧特权",
    "支付": "支付",
    "余额": "余额",
    "购买套餐将直接获得算力,购买CDK将获得激活码用于激活， 不会获得算力。": "购买套餐将直接获得算力,购买CDK将获得激活码用于激活， 不会获得算力。",
    "可获得": "可获得",
    "恭喜获得": "恭喜获得",
    "复制": "复制",
    "请选择产品": "请选择产品",
    "转账": "转账",
    "对碰设置": "对碰设置",
    "类型": "类型",
    "待设置关系ID": "待设置关系ID",
    "每日24点自动绑定": "每日24点自动绑定",
    "暂无数据": "暂无数据",
    "折叠": "折叠",
    "展开": "展开",
    "设置关系": "设置关系",
    "非关系账户无法绑定": "非关系账户无法绑定",
    "绑定的上级用户": "绑定的上级用户",
    "上级": "上级",
    "是否": "是否",
    "绑定到": "绑定到",
    "取消": "取消",
    "请输入正确的ID": "请输入正确的ID",
    "对碰绑定记录": "对碰绑定记录",
    "转账明细": "转账明细",
    "非关系账户无法转账": "非关系账户无法转账",
    "转账成功": "转账成功",
    "向": "向",
    "转入": "转入",
    "请输入正确的UID": "请输入正确的UID",
    "转账记录": "转账记录",
    "数额": "数额",
    "操作失败": '操作失败',
    "矿工费或余额不足": '矿工费或余额不足',
    "资产": '资产',
    "划转": "划转",
    "转入1": "转入",
    "转出": "转出",
    "划转成功": "划转成功",
    "密码": "密码",
    "修改密码": "修改密码",
    "请设置密码": "请设置密码",
    "原密码": "原密码",
    "设置密码": "设置密码",
    "再次输入密码": "再次输入密码",
    "请输入原密码": "请输入原密码",
    "如忘记密码请联系管理员": "如忘记密码请联系管理员",
    "请输入6位密码": "请输入6位密码",
    "两次密码不一致": "两次密码不一致",
    "设置成功": "设置成功",
    "流量": "流量",
    "数量": "数量",
    "分享": "分享",
    "裂变": "裂变",
    "裂变设置": "裂变设置",
    "裂变绑定记录": "裂变绑定记录",
    "输入密码": "输入密码",
    "静态释放": "静态释放",
    "购买模型套餐": "购买模型套餐",
    "购买cdk": "购买cdk",
    "管理操作": "管理操作",
    "请先激活账户，再获取邀请码": "请先激活账户，再获取邀请码",
    "请输入密码": "请输入密码",
    "养草值记录": "养草值记录",
    "去开户": "去开户",
    "参数系数": "参数系数",
    "杠杆倍数": "杠杆倍数",
    "对碰比例": "对碰比例",
    "更多期待": "更多期待",
    "BUINING BANK 核心优势": "BUINING BANK 核心优势",
    "体系优势：": "体系优势：",
    "全球顶级的资深银行家团队构建Al智能体系，引领全球银行进入生态4.0财富平权，用算力去战胜智力，将财富主动权交还给全球公民。": "全球顶级的资深银行家团队构建Al智能体系，引领全球银行进入生态4.0财富平权，用算力去战胜智力，将财富主动权交还给全球公民。",
    "技术优势：": "技术优势：",
    "自建人工智能理财模型，平均夏普辛26.7，最大回撤6.2%，能做到超高无风险收益比，为储户高息收入保驾护航。智能投保、衍生杠杆交易、最大化复合流动性。": "自建人工智能理财模型，平均夏普辛26.7，最大回撤6.2%，能做到超高无风险收益比，为储户高息收入保驾护航。智能投保、衍生杠杆交易、最大化复合流动性。",
    "产品优势：": "产品优势：",
    "六大契约业务全在链上进行，完全去中心化。一美金就能投资，人人可参与，随进随出。小钱参与活期、大钱放进定期、高回报衍生杠杆，抢占财富先人一步。": "六大契约业务全在链上进行，完全去中心化。一美金就能 投资，人人可参与，随进随出。小钱参与活期、大钱放 进定期、高回报衍生杠杆，抢占财富先人一步。",
    "邀请链接": "邀请链接",
    "活期存款": "活期存款",
    "定期存款": "定期存款",
    "邀请信息": "邀请信息",
    "直推人数": "直推人数",
    "团队规模": "团队规模",
    "团队资金": "团队资金",
    "我的邀请地址": "我的邀请地址",
    "温馨提示：贝灵数字人邀请链接只限于贝灵数字人使用， 贝灵王国各个应用会有独立邀请链接。": "温馨提示：贝灵数字人邀请链接只限于贝灵数字人使用， 贝灵王国各个应用会有独立邀请链接。",
    "潜在客户": "潜在客户",
    "精准客户": "精准客户",
    "分行潜在储户": "分行潜在储户",
    "分行实际储户": "分行实际储户",
    "分行储额(U)": "分行储额(U)",
    "分行活期储额": "分行活期储额",
    "分行定期储额": "分行定期储额",
    "我的邀请收益": "我的邀请收益",
    "待领取收益": "待领取收益",
    "领取成功": "领取成功",
    "领取": "领取",
    "邀请收益记录": "邀请收益记录",
    "邀请收益": "邀请收益",
    "已领取": "已领取",
    "活期": "活期",
    "定期": "定期",
    "活期总存款/未激活": "活期总存款/未激活",
    "聚合资产": "聚合资产",
    "可领取利息": "可领取利息",
    "已领取利息": "已领取利息",
    "领取利息赎回本金": "领取利息赎回本金",
    "钱包余额": "钱包余额",
    "存储": "存储",
    "储蓄时间": "储蓄时间",
    "储蓄APY": "储蓄APY",
    "定期总存款": "定期总存款",
    "基础利息+浮动利息": "基础利息+浮动利息",
    "浮动利息根据套利协议计算利": "浮动利息根据套利协议计算利",
    "我的订单": "我的订单",
    "交易详情": "交易详情",
    "保险费用": "保险费用",
    "总计": "总计",
    "根据保险协议,本次储蓄将收额外收取储蓄本金的0.5%": "根据保险协议,本次储蓄将收额外收取储蓄本金的0.5%",
    "基础利息": "基础利息",
    "浮动利息": "浮动利息",
    "可用利息": "可用利息",
    "认购金额": "认购金额",
    "套餐时间": "套餐时间",
    "存储本金": "存储本金",
    "存储数量": "存储数量",
    "选择类型": "选择类型",
    "进行中": "进行中",
    "提取利息赎回本金": "提取利息赎回本金",
    "请注意，由于您没满合约期提前提取本金和利息，您的利息将会减少。本金扣除1%": "请注意，由于您没满合约期提前提取本金和利息，您的利息将会减少。本金扣除1%",
    "抱歉，您的聚合资产不足以完成兑换操作，请确认聚合资产是否足够后再继续操作。": "抱歉，您的聚合资产不足以完成兑换操作，请确认聚合资产是否足够后再继续操作。",
    "赎回本金": "赎回本金",
    "提取利息": "提取利息",
    "本金": "本金",
    "扣除本金": "扣除本金",
    "余额不足？": "余额不足？",
    "点击此处": "点击此处",
    "可赎回数量": "可赎回数量",
    "平台钱包": "平台钱包",
    "银行钱包": "银行钱包",
    "存入成功": "存入成功",
    "兑换中...": "兑换中...",
    "禁止绑定": "禁止绑定",
    "邀请总收益": "邀请总收益",
    "活期存储不能小于100BUR": "活期存储不能小于100BUR",
    "基本利息": "基本利息",
}
