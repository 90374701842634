import axios from 'axios'
import {getToken} from '@/utils/auth'
import store from '@/store'

// create an axios instance
var VUE_APP_BASE_API = 'https://bur-2.kiman.top/api/' // 测试
// var VUE_APP_BASE_API = 'https://bringdapp.xyz/api/' // 主
// var VUE_APP_BASE_API = 'https://bringdapp01.com/api/' // 主-外
// var VUE_APP_BASE_API = 'https://api.bringdapp.com/api/' //从

let hostname = window.location.hostname
console.log(hostname);
//web端访问域名1
// if (hostname === 'burningebank.com') {
//     //web端访问域名1 对应 API 域名
//     VUE_APP_BASE_API = 'https://burningebank.com/api/'
// } else if (hostname === 'burningebank.xyz') {
//     VUE_APP_BASE_API = 'https://burningebank.xyz/api/'
// } else if (hostname === 'burning-bank.xyz') {
//     VUE_APP_BASE_API = 'https://burning-bank.xyz/api/'
// } else if (hostname === 'burning-bank.com') {
//     VUE_APP_BASE_API = 'https://burning-bank.com/api/'
// }else if (hostname === 'burninge-bank.xyz') {
//     VUE_APP_BASE_API = 'https://burninge-bank.xyz/api/'
// }

const service = axios.create({
    // baseURL: process.env.VUE_APP_BASE_API,
    baseURL: VUE_APP_BASE_API,
    timeout: 600000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        if (store.getters.token) {
            config.headers['Authorization'] = 'Bearer ' + getToken()
            config.headers['Accept-Language'] = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en'
        }
        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        const res = response.data
        // console.log(response.data);
        if (response.data.code == 422) {
            console.log(response.data);
        }

        return response
    },
    error => {
        if (error.response.status == 401) {
            console.log("登录已过期");
            return Promise.reject(error)
        }
        console.log('err' + error) // for debug

        return Promise.reject(error)
    }
)

export default service
