// ko 韩语
module.exports = {
    "敬请期待": "기대해주세요.",
    "没有更多了": "더 이상 없어요.",
    "加载中": "로드 중",
    "钱包地址": "지갑 주소",
    "首页": "메인",
    "银行": "은행",
    "数字人": "메타맨",
    "游戏": "게임파이",
    "邀请": "초대",
    "套餐": "콤보",
    "收益": "수익",
    "社区": "커뮤니티",
    "个人中心": "프로필",
    "退出登录": "연결 끊기",
    "请绑定钱包": "지갑바인딩",
    "娱乐": "엔터테인먼트",
    "生态": "생태",
    "个人资产": "개인 자산",
    "总资产": "총 자산",
    "开户": "비활성화됨",
    "元交易": "거래",
    "元储存": "입금",
    "数据契约": "데이터 계약",
    "开户成功": "계좌 개설에 성공하다.",
    "我的邀请人": "내 초대자",
    "确定": "확인하기",
    "绑定成功": "바인딩 성공",
    "请输入正确邀请码": "올바른 초대 코드를 입력하십시오.",
    "银行提示": "알림: Burning Metaman 의 초대 링크는 Burning Metaman만 사용할 수 있습니다. Burning Kingdom의 각 애플리케이션에는 독립적인 초대 링크가 있습니다.",
    "闪兑": "교환",
    "兑换的币种直接进入平台个人中心": "교환된 통화를 보려면 프로필을 입력하세요.",
    "兑换": "교환",
    "兑出币种": "통화 상환",
    "可用": "잔액",
    "全部": "Max",
    "兑入币种": "통화 상환",
    "请输入正确的数量": "올바른 수량을 입력하십시오.",
    "请输入": "입력하세요",
    "我的资产": "개인 자산",
    "种草值": "심기 포인트",
    "养草值": "성장포인트",
    "拔草值": "수확포인트",
    "特奖值": "보너스포인트:",
    "充值": "충전하기",
    "提出": "인출하다",
    "提出成功": "성공",
    "提出记录": "기록",
    "购买记录": "기록",
    "购买": "구매",
    "手续费": "수수료",
    "日期": "날짜",
    "转入地址": "전입 주소",
    "充值币种": "통화",
    "充值成功": "성공",
    "充值记录": "기록",
    "我的邀请码": "내 초대 코드",
    "邀请码": "초대 코드",
    "生成图片": "그림 생성",
    "复制链接": "링크 복사",
    "复制成功": "복제 성공",
    "扫一扫": "자세히 알아보다",
    "消息中心": "공지 사항",
    "查看详情": "세부 사항",
    "数字人提示": "알림: Burning Metaman 의 초대 링크는 Burning Metaman만 사용할 수 있습니다. Burning Kingdom의 각 애플리케이션에는 독립적인 초대 링크가 있습니다.",
    "激活": "활성화",
    "我的": "내",
    "已使用": "사용",
    "未使用": "사용되지 않음",
    "编号": "번호 지정",
    "激活成功": "성공",
    "请输入正确的CDK": "올바른 을 입력하십시오 CDK",
    "记录": "기록",
    "已完成": "완성됨",
    "未完成": "미완성",
    "失败": "실패하다",
    "审核中": "검토중",
    "兑换套餐": "콤보 교환",
    "我的业绩": "내 성과",
    "社区业绩": "커뮤니티 성과",
    "大区业绩": "지역 성과",
    "小区总业绩": "총 커뮤니티 성과",
    "直推": "직접 추천",
    "对碰": "경쟁",
    "搜索": "검색",
    "搜索UID编号": "검색UID",
    "历史搜索": "히스토리 검색",
    "下级": "하부",
    "兑换成功": "성공",
    "闪兑记录": "교환거래",
    "交易服务费": "서비스 수수료",
    "总养草值": "총성장포인트",
    "已释放养草值": "당신이 얻은 성장포인트:",
    "未释放养草值": "남은 성장포인트:",
    "已释放特奖值": "당신이 얻은 보너스포인트:",
    "未释放特奖值": "남은 보너스포인트:",
    "静态": "고정 수익",
    "动态": "동적 수익",
    "特殊": "기타",
    "详细记录": "세부기록",
    "近一周": "주간",
    "近一个月": "한달",
    "近三个月": "석달",
    "近一年": "일년",
    "加入": "가입하기",
    "复投": "재투하다",
    "欢迎获得西欧特权": "축하합니다! 서유럽에 합류했습니다!",
    "支付": "지불",
    "余额": "잔액",
    "购买套餐将直接获得算力,购买CDK将获得激活码用于激活， 不会获得算力。": "패키지를 구매하시면 컴퓨팅 파워를 직접 받으실 수 있으며, CDK를 구매하시면 활성화를 위한 활성화 코드를 받으실 수 있으나 컴퓨팅 파워를 받으실 수는 없습니다.",
    "可获得": "사용 가능",
    "恭喜获得": "축하합니다! 성장포인트 ",
    "复制": "복사학기",
    "请选择产品": "제품을 선택하십시오.",
    "转账": "이체",
    "对碰设置": "콘테스트 설정",
    "类型": "유형",
    "待设置关系ID": "관계 ID설정 대기",
    "每日24点自动绑定": "매일 24시에 자동으로 바인딩",
    "暂无数据": "데이터 없음",
    "折叠": "축소",
    "展开": "확장",
    "设置关系": "관계 설정",
    "非关系账户无法绑定": "비관계 계정은 바인딩할 수 없다",
    "绑定的上级用户": "바인딩된 상위 사용자",
    "上级": "상사",
    "是否": "여부",
    "绑定到": "바인딩 대상",
    "取消": "취소",
    "请输入正确的ID": "올바른 ID를 입력하십시오.",
    "对碰绑定记录": "기록",
    "转账明细": "세부기록",
    "非关系账户无法转账": "비관계 계좌는 이체할 수 없습니다.",
    "转账成功": "성공",
    "向": "방향",
    "转入": "전입하기",
    "请输入正确的UID": "올바른 UID를 입력하십시오.",
    "转账记录": "기록",
    "数额": "액수",
    "操作失败": '작업 실패',
    "矿工费或余额不足": '채굴 수수료 또는 잔액 부족',
    "资产": '자산',
    "划转": "회전",
    "转入1": "전입",
    "转出": "전출",
    "划转成功": "성공",
    "密码": "비밀번호",
    "修改密码": "비밀번호 변경",
    "请设置密码": "비밀번호를 설정해주세요",
    "原密码": "원래 비밀번호",
    "设置密码": "비밀번호 설정",
    "再次输入密码": "비밀번호를 다시 입력하세요",
    "请输入原密码": "원래 비밀번호를 입력해주세요",
    "如忘记密码请联系管理员": "비밀번호를 잊어버린 경우 관리자에게 문의하세요.",
    "请输入6位密码": "비밀번호 6자리를 입력하세요",
    "两次密码不一致": "두 개의 비밀번호가 일치하지 않습니다",
    "设置成功": "성공적으로 설정되었습니다",
    "流量": "트래픽",
    "数量": "수량",
    "分享": "공유",
    "裂变": "분열",
    "裂变设置": "분열 설정",
    "裂变绑定记录": "분열 바인딩 레코드",
    "银行个人中心": "은행 개인 센터",
    "数字人个人中心": "디지털 인간 개인 센터",
    "静态释放": "정적 방출",
    "购买模型套餐": "모델 패키지 구매",
    "购买cdk": "cdk 구매",
    "管理操作": "관리 작업",
    "请先激活账户，再获取邀请码": "먼저 계정을 개설하신 후 초대 코드를 받으세요.",
    "请输入密码": "비밀번호를 입력해주세요",
    "养草值记录": "양초치 기록",
    "去开户": "비활성화됨",
    "参数系数": "매개변수 계수",
    "杠杆倍数": "레버 배수",
    "对碰比例": "맞물림 비율",


    "更多期待": "기대 이상",
    "BUINING BANK 核心优势": "BUINING BANK의 핵심 장점",
    "体系优势：": "시스템의 장점:",
    "全球顶级的资深银行家团队构建Al智能体系，引领全球银行进入生态4.0财富平权，用算力去战胜智力，将财富主动权交还给全球公民。": "세계 최고의 경력을 가진 은행가 팀이 Al 인텔리전스 시스템을 구축하여 전 세계 은행을 생태 4.0으로 이끌어 재력의 평등을 실현하고, 계산 능력으로 지성을 이기어 재산의 주도권을 전 세계 시민에게 되돌려줍니다.",
    "技术优势：": "기술의 장점:",
    "自建人工智能理财模型，平均夏普辛26.7，最大回撤6.2%，能做到超高无风险收益比，为储户高息收入保驾护航。智能投保、衍生杠杆交易、最大化复合流动性。": "자체적으로 구축한 인공 지능 금융 모델로 26.7의 평균 샤프 지수와 6.2%의 최대 손실 감소를 달성하여 예금자에게 고금리 수입을 제공합니다. 지능형 보험, 파생 레버리지 거래, 최대한의 복합 유동성을 지원합니다.",
    "产品优势：": "제품의 장점:",
    "六大契约业务全在链上进行，完全去中心化。一美金就能投资，人人可参与，随进随出。小钱参与活期、大钱放进定期、高回报衍生杠杆，抢占财富先人一步。": "여섯 가지 계약 비즈니스가 모두 체인 상에서 이루어지며 완전히 탈중앙화됩니다. 1달러만 있으면 투자할 수 있으며 누구나 참여하고 언제든지 참여 및 철수할 수 있습니다. 작은 돈으로 현재 참여하고, 큰 돈으로 고정 기간에 투자하며, 고수익 파생 레버리지를 통해 부자되기의 기회를 잡으세요.",
    "邀请链接": "초대 링크",
    "活期存款": "즉시 예금",
    "定期存款": "고정 기간 예금",
    "邀请信息": "초대 정보",
    "直推人数": "직접 추천 인원",
    "团队规模": "팀 규모",
    "团队资金": "팀 자금",
    "我的邀请地址": "내 초대 주소",
    "温馨提示：贝灵数字人邀请链接只限于贝灵数字人使用， 贝灵王国各个应用会有独立邀请链接。": "안내: Beiling Digital Man 초대 링크는 Beiling Digital Man에서만 사용할 수 있으며, Beiling Kingdom의 각 응용 프로그램은 별도의 초대 링크를 제공합니다.",
    "潜在客户": "잠재 고객",
    "精准客户": "정확한 고객",
    "分行潜在储户": "지점 잠재 예금자",
    "分行实际储户": "지점 실제 예금자",
    "分行储额(U)": "지점 예금액(U)",
    "分行活期储额": "지점 즉시 예금액",
    "分行定期储额": "지점 고정 기간 예금액",
    "我的邀请收益": "내 초대 수익",
    "待领取收益": "보류 중인 수익",
    "领取成功": "성공적으로 청구함",
    "领取": "수령",
    "邀请收益记录": "초대 수익 기록",
    "邀请收益": "초대 수익",
    "已领取": "수령됨",
    "活期": "즉시",
    "定期": "고정 기간",
    "活期总存款/未激活": "전체 즉시 예금/비활성",
    "聚合资产": "집계 자산",
    "可领取利息": "청구 가능한 이자",
    "已领取利息": "수령된 이자",
    "领取利息赎回本金": "이자 수령 및 원금 상환",
    "钱包余额": "지갑 잔액",
    "存储": "저장",
    "储蓄时间": "저축 시간",
    "储蓄APY": "저축 APY",
    "定期总存款": "전체 고정 기간 예금",
    "基础利息+浮动利息": "기본 이자 + 부동 이자",
    "浮动利息根据套利协议计算利": "부동 이자는 아비트라지 계약에 따라 계산됩니다",
    "我的订单": "내 주문",
    "交易详情": "거래 세부 정보",
    "保险费用": "보험료",
    "总计": "총액",
    "根据保险协议,本次储蓄将收额外收取储蓄本金的0.5%": "보험 협약에 따라이 저축에서는 별도로 저축 원금의 0.5%를 추가로 받게 됩니다.",
    "基础利息": "기본 이자",
    "浮动利息": "부동 이자",
    "可用利息": "이용 가능한 이자",
    "认购金额": "신청 금액",
    "套餐时间": "패키지 시간",
    "存储本金": "저장 원금",
    "存储数量": "저장 수량",
    "选择类型": "유형 선택",
    "进行中": "진행 중",
    "提取利息赎回本金": "이자 청구 및 원금 상환",
    "请注意，由于您没满合约期提前提取本金和利息，您的利息将会减少。本金扣除1%": "계약 기간이 끝나기 전에 원금 및 이자를 조기에 인출하면 이자가 감소합니다. 원금 공제는 1%입니다.",
    "抱歉，您的聚合资产不足以完成兑换操作，请确认聚合资产是否足够后再继续操作。": "죄송합니다. 귀하의 집계 자산은 교환 작업을 완료하기에 충분하지 않습니다. 계속하기 전에 집계 자산이 충분한지 확인하십시오.",
    "赎回本金": "원금 상환",
    "提取利息": "이자 청구",
    "本金": "원금",
    "扣除本金": "원금 공제",
    "余额不足？": "잔액 부족?",
    "点击此处": "여기를 클릭하세요",
    "可赎回数量": "상환 가능 수량",
    "平台钱包": "플랫폼 지갑",
    "银行钱包": "은행 지갑",
    "存入成功": "입금 성공",
    "兑换中": "환전 중...",
    "禁止绑定": "바인딩 금지",
    "邀请总收益": "초청총수익",
    "活期存储不能小于100BUR": "당좌 예금은 100bur보다 작으면 안 된다",
    "基本利息": "기본 금리",

}
