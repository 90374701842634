import request from '@/utils/request'

export function login(data) {
    return request({
        url: 'login',
        method: 'post',
        data
    })
}

export function getUserInfo() {
    return request({
        url: 'info',
        method: 'get',
    })
}

export function getDigitInfo(data) {
    return request({
        url: 'info',
        method: 'get',
        params: data
    })
}

export function getConfig() {
    return request({
        url: 'config',
        method: 'post',
    })
}

export function getNotice(data) {
    return request({
        url: 'notice',
        method: 'get',
        params: data
    })
}

//套餐
export function getPackageList(data) {
    return request({
        url: 'package',
        method: 'get',
        params: data
    })
}

export function postPackage(data) {
    return request({
        url: 'package',
        method: 'post',
        data: data
    })
}

export function getPackageRecord(data) {
    return request({
        url: 'packageRecord',
        method: 'get',
        params: data
    })
}

export function buyCdk(data) {
    return request({
        url: 'buyCdk',
        method: 'post',
        data: data
    })
}

export function getCdkList(data) {
    return request({
        url: 'cdkList',
        method: 'get',
        params: data
    })
}

export function postUseCdk(data) {
    return request({
        url: 'useCdk',
        method: 'post',
        data: data
    })
}

export function getUseCdkList(data) {
    return request({
        url: 'useCdkList',
        method: 'get',
        params: data
    })
}

export function gteUnbindRelation(data) {
    return request({
        url: 'unbindRelation',
        method: 'get',
        params: data
    })
}

export function postAdjustRelation(data) {
    return request({
        url: 'adjustRelation',
        method: 'post',
        data: data
    })
}

export function getAdjustRelation(data) {
    return request({
        url: 'adjustRelationRecord',
        method: 'get',
        params: data
    })
}

//闪兑
export function postSwap(data) {
    return request({
        url: 'swap',
        method: 'post',
        data: data
    })
}

export function getSwapRecord(data) {
    return request({
        url: 'swapRecord',
        method: 'get',
        params: data
    })
}

export function getPowerRecord(data) {
    return request({
        url: 'records',
        method: 'get',
        params: data
    })
}

//转账
export function postTransfer(data) {
    return request({
        url: 'transfer',
        method: 'post',
        data: data
    })
}

export function gteTransferRecord(data) {
    return request({
        url: 'transferRecord',
        method: 'get',
        params: data
    })
}

//数字人-收益
export function getDigitized(data) {
    return request({
        url: 'digitized',
        method: 'get',
        params: data
    })
}

//充值参数
export function getRechargeParams(data) {
    return request({
        url: 'rechargeParams',
        method: 'get',
        params: data
    })
}

//充值提现记录
export function getExchangeRecord(data) {
    return request({
        url: 'exchangeRecord',
        method: 'get',
        params: data
    })
}

export function postExchange(data) {
    return request({
        url: 'exchange',
        method: 'post',
        data: data
    })
}

export function getInviteCode(data) {
    return request({
        url: 'inviteCode',
        method: 'get',
    })
}

//银行开户
export function activateBank(data) {
    return request({
        url: 'activate',
        method: 'post',
    })
}

export function getCommunity(data) {
    return request({
        url: 'community',
        method: 'get',
    })
}

export function getCommunityInfo(data) {
    return request({
        url: 'communityInfo',
        method: 'get',
        params: data
    })
}

//绑定邀请码
export function postBindCode(data) {
    return request({
        url: 'bindCode',
        method: 'post',
        data: data
    })
}

export function postMark(data) {
    return request({
        url: 'mark',
        method: 'post',
        data: data
    })
}

export function getMarkRecord(data) {
    return request({
        url: 'markRecord',
        method: 'get',
        params: data
    })
}

export function postTradePwd(data) {
    return request({
        url: 'tradePwd',
        method: 'post',
        data: data
    })
}

//获取合约相关参数
export function getBankParams(data) {
    return request({
        url: 'bankParams',
        method: 'get',
    })
}

export function getBankInfo(data) {
    return request({
        url: 'bankInfo',
        method: 'get',
    })
}

//邀请奖励记录
export function getRewardRecord(data) {
    return request({
        url: 'rewardRecord',
        method: 'get',
        params: data
    })
}


export function bankOrder(data) {
    return request({
        url: 'bankOrder',
        method: 'get',
        params: data
    })
}

// 邀请信息
export function inviteInfo(data) {
    return request({
        url: 'inviteInfo',
        method: 'get',
        params: data
    })
}
