<template>
  <div id="app">
    <router-view/>
    <van-overlay
        :custom-style="{'background-color': '#1c1c23'}"
        :z-index="100"
        :lock-scroll="false"
        :show="loadingShow"
    >
      <div class="wrapper">
        <van-loading size="50px"/>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import {getToken} from '@/utils/auth'
import Web3 from 'web3'
import {Dialog} from 'vant';
import abi from './abi/erc20_abi.json'
import {ethers} from 'ethers';
import {getBankParams} from "./api";


export default {
  name: 'App',
  data() {
    return {
      dialog: true,
      loadingShow: this.$store.getters.openEnv,
      loginForm: {
        address: '',
        invite_code: ''
      },
      tokenContract: "",
      provider: '',
      accounts: '',
      wallet_name: '',
      token: getToken(),
    }
  },
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  watch: {
    '$store.getters.openEnv'(newVal, oldVal) {
      // console.log(newVal, '监听vux值')
      this.loadingShow = this.$store.getters.openEnv
    }
  },
  created() {
    this.getBankParams()
    this.getParams()
    this.getWalletAdd()
    console.log('token', this.token);
    if (this.token) {
      this.getUserInfo()
    } else {
      this.$router.push({path: '/'});
    }

    // this.sign_w3()
  },
  mounted() {
    // window.ethereum.request({method: 'eth_chainId'}).then(res => {
    //   console.log("但无论如何都不能直接获取用户配置的PRC，但可以拿到链ID", res);
    // });
  },
  methods: {
    getBankParams() {
      this.$store.dispatch('bur/getBankParams')
          .then(({data}) => {
            console.log("BankParams == >", data);
          })
    },
    getParams() {
      let url = window.location.href
      if (url.split('?').length > 1) {
        let cs_arr = url.split('?')[1]
        let param_arr = cs_arr.split('=')
        if (param_arr[0] == 'invite_code') {
          let invite_code = param_arr[1]
          let index = invite_code.indexOf('&');
          if (index !== -1) {
            invite_code = invite_code.substring(0, index);
          }
          // if (invite_code.length > 16) {
          //   invite_code = invite_code.substring(0, 16);
          //   console.log(invite_code.length, invite_code);
          // }
          // console.log(invite_code.length, invite_code);
          sessionStorage.setItem('invite_code', invite_code)
          // this.loginForm.invite_code = invite_code
        }
      }

    },
    getUserInfo() {
      this.$store.dispatch('app/getUserInfo')
          .then((res) => {
            console.log(res);
            let add = this.$store.getters.walletAdd
            // console.log(add);
            // console.log(res.data.address);
            if (add && add.toLowerCase() != (res.data.address).toLowerCase()) {
              console.log('------');
              window.ethereum.isConnected()
              this.$store.dispatch('app/logOut')
            } else {
              console.log(this.$store.getters.walletAdd);
            }

          })
    },
    async sign_w3() {
      let that = this;
      window.ethereum.enable().then(function () {
        that.provider = new ethers.providers.Web3Provider(window.web3.currentProvider);
        that.provider.getNetwork().then(function (result) {
          if (result['chainId'] != 1) {

            console.log("Switch to Mainnet!")

          } else { // okay, confirmed we're on mainnet

            that.provider.listAccounts().then(function (result) {
              console.log(result);
              that.accountAddress = result[0]; // figure out the user's Eth address
              that.provider.getBalance(String(result[0])).then(function (balance) {
                var myBalance = (balance / ethers.constants.WeiPerEther).toFixed(4);
                console.log("Your Balance: " + myBalance);
              });

              // get a signer object so we can do things that need signing
              that.signer = that.provider.getSigner();

              var rightnow = (Date.now() / 1000).toFixed(0)
              var sortanow = rightnow - (rightnow % 600)

              console.log("Signing in to " + document.domain + " at " + sortanow, that.accountAddress, "test password!");
              that.signer.signMessage("Signing in to " + document.domain + " at " + sortanow, that.accountAddress, "test password!")
                  .then((signature) => {
                    console.log(that.accountAddress, signature);
                    // that.handleAuth(accountAddress, signature);
                  });

              console.log(that.signer);
            })
          }
        })
      })
    },
    // 唤起钱包
    async getWalletAdd() {
      // console.log(window.ethereum);
      // if (window.ethereum) {
      console.log(window["ethereum"]);
      console.log(window.web3);

      // console.log(window.web3.currentProvider.isMetaMask);
      // console.log(window.web3.currentProvider.isTrust);
      // console.log(window.web3.currentProvider.isTrustWallet);


      if (typeof window.ethereum == "undefined" && typeof window.web3 == "undefined") {
        this.$store.dispatch('app/setOpenenv', false)
        // this.$router.push('/remind')
        console.log("请安装MetaMask钱包");
        return;
      }
      // if (true) {
      //有ethereum就用ethereum 没有就用web3
      const provider = window["ethereum"] || window.web3.currentProvider;
      this.provider = provider;
      const accounts = await provider.request({method: "eth_accounts"});
      this.accounts = accounts;

      if (provider.isMetaMask) {
        console.log('MetaMask');
        this.wallet_name = 'MetaMask'
      } else if (provider.isTrust || provider.isTrustWallet) {
        console.log('Trust Wallet');
        this.wallet_name = 'Trust Wallet'
      }

      //如果当前没有钱包连接
      if (accounts.length <= 0) {
        try {
          //如果没登录 调用登录 成功后返回钱包地址列表
          const eth_requestAccounts = await provider.request({method: "eth_requestAccounts"});

          this.accounts = eth_requestAccounts;

          let add = eth_requestAccounts[0]
          this.$store.dispatch('app/setWalletAdd', add)
          // await this.login(add)
          // this.connectionWeb3(provider, eth_requestAccounts[0])
        } catch (e) {
          console.log('连接被拒绝');
        }
      } else {

        let add = accounts[0]
        this.$store.dispatch('app/setWalletAdd', add)
        // this.$store.dispatch('app/setOpenenv', false)
        // await this.login(add)
        // this.connectionWeb3(provider, accounts[0])
      }
      // window.ethereum.enable()
      //     .then((res) => {
      //         // console.log(("当前钱包地址:" + res[0]))
      //         let add = res[0]
      //         this.loginForm.address = add
      //         this.$store.dispatch('app/setWalletAdd', add)
      //         // this.$store.dispatch('app/setOpenenv', false)
      //
      //         this.login()
      //     })
      //     .catch(() => {
      //         console.log('获取失败');
      //         this.$store.dispatch('app/setOpenenv', true)
      //     })

      // } else {
      //     this.$store.dispatch('app/setOpenenv', false)
      //     this.$router.push('/remind')
      //     console.log("请安装MetaMask钱包");
      // }
    },

    //初始化合约
    async connectionWeb3(provider, accounts) {
      // this.accountData.usdt_contract_address
      let tokenAddress = this.$store.getters.accountData.usdt_contract_address
      // console.log(tokenAddress);
      let web3s = new Web3(provider);
      // this.utilWeb3 = web3s.utils;
      // 加载Token(USDT)合约
      // let tokenAddress = "0xdAC17F958D2ee523a2206206994597C13D831ec7"; // 这个是真实的ETH的USDT
      this.tokenContract = await new web3s.eth.Contract(abi, tokenAddress, {
        from: accounts
      });

      // console.log(this.tokenContract);
      this.$store.dispatch('app/setTokenContract', this.tokenContract)
      // this.walletAddress = accounts;
      // //检测链ID
      // const chainId = parseInt(provider.chainId);
      // this.chainId = chainId
    },
  }
}
</script>

<style lang="less">

/*@font-face {*/
/*    font-family: TVPSVR;// 自定义名称，无需引号*/
/*    src: url('./assets/flont/TVPSVainCapitalRegular.ttf')*/
/*}*/

/*@font-face {*/
/*    font-family: GILROY;// 自定义名称，无需引号*/
/*    src: url('./assets/flont/Gilroy.ttf')*/
/*}*/

@font-face {
  font-family: ALIBABAPUHUITI;// 阿里巴巴惠普体
  src: url('./assets/flont/AlibabaPuHuiTi-3-55-Regular.ttf')
}

@font-face {
  font-family: FUTURA_BOLD;// FUTURA_BOLD
  src: url('./assets/flont/Futura-Bold-5.ttf')
}

@font-face {
  font-family: FUTURA_MEDIUM;// Futura-Medium
  src: url('./assets/flont/Futura-Medium-6.ttf')
}

@font-face {
  font-family: PangMenZhengDaoBiaoTiTiMianFeiBan;// 外门邪道标题
  src: url('./assets/flont/PangMenZhengDaoBiaoTiTiMianFeiBan-2.ttf')
}

body {
  //font-family: ALIBABAPUHUITI;
  font-size: 16px;
  background-color: #000000;
  color: #fff;
  -webkit-font-smoothing: antialiased;
}

html, body, p, ol, ul, li, dl, dt, dd, blockquote, figure, fieldset, legend, textarea, pre, iframe, hr, h1, h2, h3, h4, h5, h6 {
  padding: 0;
  margin: 0;
}


// 顶部导航
.van-nav-bar {
  background-color: #1c1c23;

  .van-nav-bar__title {
    color: #fff;
    font-weight: bold;

  }
}

.van-hairline--bottom::after {
  border-bottom-width: 0;
}

.page-title {
  position: relative;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 10;

  .title-wrap {
    margin-left: 1rem;

    h1 {
      text-align: left;
      margin-bottom: 0
    }
  }
}

.has-text-centered {
  text-align: center
}

.is-4 {
  font-weight: 700 !important
}

.reward-placeholder {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-bottom: 30px;
  align-items: center;
  color: #666680
}

.reward-placeholder svg {
  width: 35px;
  height: 35px
}

.reward-placeholder p {
  margin-top: 10px;
  text-align: center;
  color: #666680;
  font-size: 14px
}

.van-cell {
  padding: 0;
  background-color: #313131;
}

.van-field__control {
  color: #fff !important;
}

.recgarge_font {
  .van-field__control::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.8);
    font-size: 0.88rem;
    font-weight: 400;
  }
}

.coin_cont, .input_cont {
  .van-field__control::-webkit-input-placeholder {
    color: #8F92A1;
    font-size: 1rem;
    font-weight: 400;
  }
}


.van-radio__label {
  color: #fff;
}

.van-action-sheet {
  line-height: 1.5rem;
  background: #1c1c23;
  color: #fff;
}

.van-cell::after {
  border-bottom: none;
  /*border-bottom: 2px solid rgba(207, 207, 252, .15)*/
}

.van-tab {
  width: 30vw;
}

#box {
  -webkit-animation-name: fadeIn; /*动画名称*/
  -webkit-animation-duration: 0.6s; /*动画持续时间*/
  -webkit-animation-iteration-count: 1; /*动画次数*/
  -webkit-animation-delay: 0s; /*延迟时间*/
}

@-webkit-keyframes fadeIn {
  0% {

    opacity: 0; /*初始状态 透明度为0*/
    transform: translateY(-20px)
  }
  /*50% {*/

  /*opacity: 0.5; !*中间状态 透明度为0*!*/
  /*transform: translateY(-50px);*/
  /*}*/
  100% {
    opacity: 1; /*结尾状态 透明度为1*/
    transform: translateY(0);
  }
}

.count-down-cont {
  display: flex;
  padding: 0 0.6rem;
  margin-top: 2rem;

  .count-down-item {
    position: relative;
    flex: 1;
    text-align: center;

    .date {
      position: relative;

      border-radius: 16px;
      border: 1px solid rgba(255, 255, 255, 0);
      background-clip: padding-box;
      /*padding: 0.8rem 0.5rem;*/
      /*background: #30303b;*/
      /*font-size: 1.2rem;*/
      /*border-radius: 0.8rem;*/
      margin: 0 6px;
      /*font-weight: bolder;*/
      /*color: #fff;*/
    }

    .countdown-timer {
      position: relative;
      z-index: 1;
      font-weight: 700;
      background: #30303b;
      font-size: 24px;
      padding: 1rem 0;
      min-width: 20vw;
      color: #fff;
      text-align: center;
      margin-bottom: 5px;
      border-radius: 16px;
    }

    .border-gradient {
      position: absolute;
      width: 100%;
      height: 95%;
      top: 0;
      bottom: 0;
      left: -2px;
      right: 0;
      background: linear-gradient(to right, #cfcffc26, #30303b 60%);
      /*z-index: -1;*/
      border-radius: 16px;
    }

    .unit {
      line-height: 2rem;
      font-size: 0.8rem;
    }
  }

}

.van-popup {
  background: transparent;
}

.custom-modal-cont {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .7);
  z-index: 2;

  .list-cont {
    overflow-y: scroll;
    max-height: 80vh;
  }

  .loadMore {
    text-align: center;
    font-size: 0.9rem;
  }
}

.field-input {
  .van-field__control {
    color: #fff !important;
  }
}

.van-toast {
  color: #E7BD71;
  background: #2D2D2D;
}

//
.modal-cont {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  //background-color: rgba(45, 45, 45, 0.37);
  z-index: 99;

  .content-cont {
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 8vw;
    width: 70vw;
    margin: auto;
    box-sizing: border-box;
    height: 16.86rem;
    background: #2D2D2D;
    border-radius: 1.07rem;
  }

  .title {
    font-size: 1.14rem;
    font-weight: bold;
    color: #E7BD71;
    margin-bottom: 2.1rem;
  }

  .info {
    font-size: 1.14rem;
    color: #E7BD71;
    line-height: 2.14rem;
  }

  .but-cont {
    padding-top: 2rem;
    overflow: hidden;

    > div {
      width: 40%;
      display: inline-block;

      font-size: 1.14rem;
    }

    .but-left {
      float: left;
      line-height: 2.5rem;
      border: 1px solid #E7BD71;
      border-radius: 2rem;
      color: #E7BD71;
    }

    .but-right {
      float: right;
      line-height: 2.5rem;
      background: linear-gradient(270deg, #E8BD70 0%, #EDD185 100%);
      border-radius: 2rem;
      color: #000000;
    }
  }
}


.operation-success {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;

  .success-info-cont {
    position: relative;
    width: 70vw;
    margin: auto;
    border-radius: 1.07rem;

    ._bg {
      width: 100%;
    }

    .success-info {
      position: absolute;
      top: 20%;
      left: 0;
      right: 0;
      width: 90%;
      margin: auto;
      text-align: center;

      .title {
        font-size: 1.14rem;
        font-weight: bold;
        color: #E7BD71;
        padding-bottom: 1.1rem;
      }

      .text {
        font-size: 1.14rem;
        color: #E7BD71;
      }


    }

    ._but {
      position: absolute;
      bottom: 2.1rem;
      left: 0;
      right: 0;
      margin: auto;
      width: 85%;
      line-height: 2.5rem;
      color: #000;
      text-align: center;
      font-size: 1.14rem;
      background: linear-gradient(270deg, #E8BD70 0%, #EDD185 100%);
      border-radius: 1.43rem;
    }
  }
}

.van-list__error-text, .van-list__finished-text, .van-list__loading {
  color: #6E6045
}

.van-loading, .van-loading__text {
  color: #6E6045
}
</style>
