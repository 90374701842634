import Web3 from 'web3';
import {getBankParams} from '@/api/index'
import {keepDecimal} from '@/utils/tools.js'
import BigNumber from "bignumber.js";
//BUR合约地址
const burApproveCont = '0xE4FFD527bbBA9986CD57B3b0ae56fB0955ec2b90';

// // 邀请合约地址
// const invitationApproveCont = '0x1A3B548b152Ff3b20878aF1f32F9247c6D38A884';

// //银行质押合约地址
// const bankApproveCont = '0xD2e2DA20EDe6163bAe1e45bF4cd61C2A1Ae4B981';

const rpcUrl = 'https://exchaintestrpc.okex.org/';
const userAbi = require('../../abi/abi.json');
const rewardAbi = require('../../abi/reward.json');
const bankAbi = require('../../abi/bank.json');
const usdtAbi = require('../../abi/usdt.json');
const swapAbi = require('../../abi/swap.json');
const burAbi = require('../../abi/bur.json');

const state = {
    bankParams: {
        //银行质押
        "pledge": {
            "abi": bankAbi,
            "address": "0x9131fBEeed0E79F50322664501C2950663BC3882",
        },
        //邀请合约
        "reward": {
            "abi": rewardAbi,
            "address": "0x1A3B548b152Ff3b20878aF1f32F9247c6D38A884",
        },
        //闪兑合约
        "swap": {
            "abi": swapAbi,
            "address": "0x77FAa5A81199482661478027b33819Ae45BCd7a6",
        },
        //USDT
        "usdt": {
            "abi": usdtAbi,
            "address": "0x19e97BaEee2919D42cf3B23722dE8603A9DB70a5",
        },
        //BUR
        "bur": {
            "abi": burAbi,
            "address": "0xE4FFD527bbBA9986CD57B3b0ae56fB0955ec2b90",
        }
    },
    // web3: new Web3(rpcUrl),
    web3: new Web3(window.web3.currentProvider),
    userContract: null,
    rewardContract: null,
    bankContract: null,
    usdtContract: null,
    burContract: null,
    swapContract: null,
}

const mutations = {
    SET_BANK_PARAMS: (state, bankParams) => {
        state.bankParams = bankParams
    },
    SET_REWARD_CONTRACT: (state, rewardContract) => {
        state.rewardContract = rewardContract
    },
    SET_BANK_CONTRACT: (state, bankContract) => {
        state.bankContract = bankContract
    },
    SET_USER_CONTRACT: (state, userContract) => {
        state.userContract = userContract
    },
    SET_USDT_CONTRACT: (state, usdtContract) => {
        state.usdtContract = usdtContract
    },
    SET_BUR_CONTRACT: (state, burContract) => {
        state.burContract = burContract
    },
    SET_SWAP_CONTRACT: (state, swapContract) => {
        state.swapContract = swapContract
    },
}

const actions = {
    getBankParams({commit, state}) {
        return new Promise((resolve, reject) => {
            getBankParams()
                .then(response => {
                    if (response.data.code == 200) {
                        commit('SET_BANK_PARAMS', response.data.data)
                        // commit('SET_USDT_CONTRACT', response.data.data.usdt.address)
                    }
                    console.log('bank--------------', state.bankParams);
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getAccount({commit}) {
        return new Promise(((resolve, reject) => {
                const provider = window["ethereum"] || window.web3.currentProvider;
                this.provider = provider;
                provider.request({method: "eth_accounts"})
                    .then(res => {
                        // console.log(res);
                        if (res.length > 0) {
                            resolve(res[0])
                        }
                        resolve(null)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        )
    },
    initUser({commit}, data) {
        return new Promise(((resolve, reject) => {
                const web3 = state.web3;
                const contract = new web3.eth.Contract(userAbi, burApproveCont)
                commit('SET_USER_CONTRACT', contract)
            })
        )
    },
    initReward({commit, state}, data) {
        return new Promise(((resolve, reject) => {
                const web3 = state.web3;
                // const contract = new web3.eth.Contract(rewardAbi, invitationApproveCont)
                const contract = new web3.eth.Contract(state.bankParams.reward.abi, state.bankParams.reward.address)
                commit('SET_REWARD_CONTRACT', contract)
            })
        )
    },
    initBank({commit}, data) {
        return new Promise(((resolve, reject) => {
                const web3 = state.web3;
                // const contract = new web3.eth.Contract(bankAbi, bankApproveCont)
                const contract = new web3.eth.Contract(state.bankParams.pledge.abi, state.bankParams.pledge.address)
                commit('SET_BANK_CONTRACT', contract)
            })
        )
    },
    initUsdt({commit}, data) {
        return new Promise(((resolve, reject) => {
                const web3 = state.web3;
                const contract = new web3.eth.Contract(state.bankParams.usdt.abi, state.bankParams.usdt.address)
                commit('SET_USDT_CONTRACT', contract)
            })
        )
    },
    initBur({commit}, data) {
        return new Promise(((resolve, reject) => {
                const web3 = state.web3;
                const contract = new web3.eth.Contract(state.bankParams.bur.abi, state.bankParams.bur.address)
                commit('SET_BUR_CONTRACT', contract)
            })
        )
    },
    initSwap({commit}, data) {
        return new Promise(((resolve, reject) => {
                const web3 = state.web3;
                const contract = new web3.eth.Contract(state.bankParams.swap.abi, state.bankParams.swap.address)
                commit('SET_SWAP_CONTRACT', contract)
            })
        )
    },
    // 获取授权数量
    getAllowance({dispatch, commit}, account) {
        dispatch('initUser')
        return new Promise(((resolve, reject) => {
                const web3 = state.web3;
                state.userContract.methods.allowance(account, state.bankParams.pledge.address).call()
                    .then((result) => {
                        let allowance = web3.utils.fromWei(result, 'ether')
                        console.log('Token授权数量：' + web3.utils.fromWei(result, 'ether'));
                        resolve(allowance)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        )
    },
    // 获取用户USDT数量
    getAllowanceSwap({dispatch, commit}, account) {
        dispatch('initUsdt')
        return new Promise(((resolve, reject) => {
                const web3 = state.web3;
                state.usdtContract.methods.allowance(account, state.bankParams.swap.address).call()
                    .then((result) => {
                        let allowance = web3.utils.fromWei(result, 'ether')
                        console.log('swap授权数量：' + web3.utils.fromWei(result, 'ether'));
                        resolve(allowance)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        )
    },
    // 获取 BUR兑换成聚合资产的swap授权数量
    getAllowanceBurSwap({dispatch, commit}, account) {
        dispatch('initBur')
        console.log('state.bankParams.pledge.address----', state.bankParams.pledge.address)
        return new Promise(((resolve, reject) => {
                const web3 = state.web3;
                state.burContract.methods.allowance(account, state.bankParams.pledge.address).call()
                    .then((result) => {
                        let allowance = web3.utils.fromWei(result, 'ether')
                        console.log('swapBur授权数量：' + web3.utils.fromWei(result, 'ether'));
                        resolve(allowance)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        )
    },

    // 获取主网地址余额
    getBalance({dispatch, commit}, account) {
        dispatch('initReward')
        return new Promise(((resolve, reject) => {
                const web3 = state.web3;
                web3.eth.getBalance(account).then((result) => {
                    console.log('获取主网地址余额：' + web3.utils.fromWei(result, 'ether'));
                }).catch((error) => {
                    console.error(error); // 处理错误
                });
                // 读取手续费nonce
                web3.eth.getTransactionCount(account, (err, txCount) => {
                    console.log('获取nonce：' + txCount)
                })
                // 获取当前gasPrice
                web3.eth.getGasPrice((err, result) => {
                    console.log("当前手续费价格：" + web3.utils.fromWei(result, 'ether'));
                })

            })
        )
    },
    //获取个人已领取的奖励金
    getAlreadyRewards({dispatch, commit}, account) {
        dispatch('initReward')
        return new Promise(((resolve, reject) => {
                const web3 = state.web3;
                state.rewardContract.methods.alreadyRewards(account).call().then((result) => {
                    console.log('已领取的奖励金', web3.utils.fromWei(result, 'ether'));
                    const res = web3.utils.fromWei(result, 'ether')
                    resolve(keepDecimal(res))
                }).catch((error) => {
                    console.error(error); // 处理错误
                    reject(error)
                });
            })
        )
    },
    //个人未领取的奖励金
    getUnclaimedRewards({dispatch, commit}, account) {
        dispatch('initReward')
        return new Promise(((resolve, reject) => {
                const web3 = state.web3;
                state.rewardContract.methods.unclaimedRewards(account).call().then((result) => {
                    console.log('未领取的奖励金', web3.utils.fromWei(result, 'ether'));
                    const res = web3.utils.fromWei(result, 'ether')
                    resolve(keepDecimal(res))
                }).catch((error) => {
                    console.error(error); // 处理错误
                    reject(error)
                });
            })
        )
    },
    //提取奖励
    claimReward({dispatch, commit, state}, account) {
        dispatch('initReward')
        return new Promise(((resolve, reject) => {
                const web3 = state.web3;
                const gasLimit = web3.utils.toHex(300000);
                const gasPrice = web3.utils.toHex(web3.utils.toWei('3', 'gwei'));
                state.rewardContract.methods.claimReward()
                    .send({from: account, gasLimit, gasPrice})
                    .then((result) => {
                        console.log(result);
                        resolve(result)
                    }).catch((error) => {
                    console.error(error); // 处理错误
                    reject(error)
                });
            })
        )
    },
    //活期存款
    depositCurrent({dispatch, commit}, {account, amount}) {
        dispatch('initBank')
        return new Promise(((resolve, reject) => {
                const web3 = state.web3;
                const gasLimit = web3.utils.toHex(300000);
                const gasPrice = web3.utils.toHex(web3.utils.toWei('3', 'gwei'));
                //存入数量
                amount = web3.utils.toHex(web3.utils.toWei(amount, 'ether'))
                state.bankContract.methods.depositCurrent(amount)
                    .send({from: account, gasLimit, gasPrice})
                    .then((result) => {
                        console.log(result);
                        resolve(result)
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error); // 处理错误
                    });
            })
        )
    },
    //提取活期存款
    withdrawCurrent({dispatch, commit}, account) {
        dispatch('initBank')
        return new Promise(((resolve, reject) => {
                const web3 = state.web3;
                const gasLimit = web3.utils.toHex(300000);
                const gasPrice = web3.utils.toHex(web3.utils.toWei('3', 'gwei'));
                state.bankContract.methods.withdrawCurrent()
                    .send({from: account, gasLimit, gasPrice})
                    .then((result) => {
                        console.log(result);
                        resolve(result)
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error); // 处理错误
                    });
            })
        )
    },
    //获取活期存款
    getCurrentDeposit({dispatch, commit}, account) {
        dispatch('initBank')
        return new Promise(((resolve, reject) => {
                const web3 = state.web3;
                state.bankContract.methods.currentDeposit(account).call().then((result) => {
                    // console.log(result);
                    console.log('获取活期存款', web3.utils.fromWei(result.amount, 'ether'));
                    const amount = web3.utils.fromWei(result.amount, 'ether')
                    resolve(keepDecimal(amount))
                }).catch((error) => {
                    reject(error)
                    console.error(error); // 处理错误
                });
            })
        )
    },

    getCurrentDepositReward({dispatch, commit}, account) {
        dispatch('initBank')
        return new Promise(((resolve, reject) => {
                const web3 = state.web3;
                state.bankContract.methods.currentDeposit(account).call().then((result) => {
                    // console.log(result);
                    console.log('获取活期存款', web3.utils.fromWei(result.amount, 'ether'));
                    const amount = web3.utils.fromWei(result.reward, 'ether')
                    resolve(keepDecimal(amount))
                }).catch((error) => {
                    reject(error)
                    console.error(error); // 处理错误
                });
            })
        )
    },

    getCurrentDepositTimestamp({dispatch, commit}, account) {
        dispatch('initBank')
        return new Promise(((resolve, reject) => {
                const web3 = state.web3;
                state.bankContract.methods.currentDeposit(account).call().then((result) => {
                    if (result.depositTime) {
                        resolve(result.depositTime * 1000)
                    } else {
                        resolve('-')
                    }
                }).catch((error) => {
                    reject(error)
                    console.error(error); // 处理错误
                });
            })
        )
    },

    getCurrentDepositTimestamp1({dispatch, commit}, account) {
        dispatch('initBank')
        return new Promise(((resolve, reject) => {
                const web3 = state.web3;
                state.bankContract.methods.currentDeposit(account).call().then((result) => {
                    resolve(result.depositTime)
                }).catch((error) => {
                    reject(error)
                    console.error(error); // 处理错误
                });
            })
        )
    },

    getCurrentReward({dispatch, commit}, {amount, timestamp}) {
        dispatch('initBank')
        return new Promise(((resolve, reject) => {
                const web3 = state.web3;
                amount = web3.utils.toWei(amount, 'ether');
                state.bankContract.methods.getCurrentReward(amount, timestamp).call().then((result) => {
                    console.log('amount-----timestamp', amount, timestamp)
                    console.log('11112222223556777777-', result);
                    const amount = web3.utils.fromWei(result, 'ether')
                    resolve(keepDecimal(amount))
                }).catch((error) => {
                    reject(error)
                    console.error(error); // 处理错误
                });
            })
        )
    },


    getDays({dispatch, commit}, timestamp) {
        dispatch('initBank')
        return new Promise(((resolve, reject) => {
                const web3 = state.web3;
                state.bankContract.methods.getDays(timestamp).call().then((result) => {
                    console.log('getDay-------', result);
                    // console.log('获取活期存款', web3.utils.fromWei(result.amount, 'ether'));
                    // const amount = web3.utils.fromWei(result.reward, 'ether')
                    resolve(keepDecimal(result))
                }).catch((error) => {
                    reject(error)
                    console.error(error); // 处理错误
                });
            })
        )
    },

    //定期存款
    depositFixed({dispatch, commit}, {account, amount, index}) {
        dispatch('initBank')
        return new Promise(((resolve, reject) => {
                const web3 = state.web3;
                const gasLimit = web3.utils.toHex(300000);
                const gasPrice = web3.utils.toHex(web3.utils.toWei('3', 'gwei'));
                //存入数量
                amount = web3.utils.toHex(web3.utils.toWei(amount, 'ether'))
                state.bankContract.methods.depositFixed(amount, web3.utils.toHex(index))
                    .send({from: account, gasLimit, gasPrice})
                    .then((result) => {
                        console.log(result);
                        resolve(result)
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error); // 处理错误
                    });
            })
        )
    },
    //获取定期存款
    getUsrFixedHasDeposit({dispatch, commit}, account) {
        dispatch('initBank')
        return new Promise(((resolve, reject) => {
                const web3 = state.web3;
                state.bankContract.methods.usrFixedHasDeposit(account).call().then((result) => {
                    // console.log(result);
                    console.log('获取定期存款', web3.utils.fromWei(result, 'ether'));
                    const amount = web3.utils.fromWei(result, 'ether')
                    resolve(keepDecimal(amount))
                }).catch((error) => {
                    reject(error)
                    console.error(error); // 处理错误
                });
            })
        )
    },
    //
    withdrawFixed({dispatch, commit}, {account, id}) {
        dispatch('initBank')
        return new Promise(((resolve, reject) => {
                const web3 = state.web3;
                const gasLimit = web3.utils.toHex(300000);
                const gasPrice = web3.utils.toHex(web3.utils.toWei('3', 'gwei'));
                state.bankContract.methods.withdrawFixed(id)
                    .send({from: account, gasLimit, gasPrice})
                    .then((result) => {
                        console.log(result);
                        resolve(result)
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error); // 处理错误
                    });
            })
        )
    },
    //获取余额
    getBalanceOf({dispatch, commit}, account) {
        dispatch('initUser')
        return new Promise(((resolve, reject) => {
                const web3 = state.web3;
                state.userContract.methods.balanceOf(account).call().then((result) => {
                    // console.log(result);
                    console.log('获取余额', web3.utils.fromWei(result, 'ether'));
                    const amount = web3.utils.fromWei(result, 'ether')
                    console.log(amount);
                    resolve(keepDecimal(amount))
                }).catch((error) => {
                    reject(error)
                    console.error(error); // 处理错误
                });
            })
        )
    },
    //获取聚合资产
    getPolymerizeBalanceOf({dispatch, commit}, account) {
        dispatch('initBank')
        return new Promise(((resolve, reject) => {
                const web3 = state.web3;
                state.bankContract.methods.balanceOf(account).call().then((result) => {
                    // console.log(result);
                    console.log('获取聚合资产', web3.utils.fromWei(result, 'ether'));
                    const amount = web3.utils.fromWei(result, 'ether')
                    console.log(amount);
                    resolve(keepDecimal(amount))
                }).catch((error) => {
                    reject(error)
                    console.error(error); // 处理错误
                });
            })
        )
    },
    // currentHasReward - 已领取利息
    getCurrentHasReward({dispatch, commit}, account) {
        dispatch('initBank')
        return new Promise(((resolve, reject) => {
                const web3 = state.web3;
                state.bankContract.methods.currentHasReward(account).call().then((result) => {
                    // console.log(result);
                    console.log('已领取利息', web3.utils.fromWei(result, 'ether'));
                    const amount = web3.utils.fromWei(result, 'ether')
                    console.log(amount);
                    resolve(keepDecimal(amount))
                }).catch((error) => {
                    reject(error)
                    console.error(error); // 处理错误
                });
            })
        )
    },
    //  可领取利息
    getDepositReward({dispatch, commit}, account) {
        dispatch('initBank')
        return new Promise(((resolve, reject) => {
                const web3 = state.web3;
                state.bankContract.methods.getDepositReward(account).call().then((result) => {
                    // console.log(result);
                    console.log('已领取利息', web3.utils.fromWei(result, 'ether'));
                    const amount = web3.utils.fromWei(result, 'ether')
                    // console.log(amount);
                    resolve(keepDecimal(amount))
                }).catch((error) => {
                    reject(error)
                    console.error(error); // 处理错误
                });
            })
        )
    },


    //获取level
    getLevelPeriod({dispatch, commit}) {
        dispatch('initBank')
        return new Promise(((resolve, reject) => {
                const web3 = state.web3;
                state.bankContract.methods.levelPeriod().call().then((result) => {
                    resolve(result)
                }).catch((error) => {
                    reject(error)
                    console.error(error); // 处理错误
                });
            })
        )
    },

    getBlock({dispatch, commit}) {
        dispatch('initBank')
        return new Promise(((resolve, reject) => {
                const web3 = state.web3;
                web3.eth.getBlock('latest').then((result) => {
                    // console.log(result.timestamp);
                    resolve(result.timestamp)
                }).catch((error) => {
                    reject(error)
                    console.error(error); // 处理错误
                });
            })
        )
    },

    //获取USDT余额
    getUsdtBalance({dispatch, commit}, account) {
        dispatch('initUsdt')
        return new Promise(((resolve, reject) => {
                const web3 = state.web3;
                state.usdtContract.methods.balanceOf(account).call().then((result) => {
                    console.log('getUsdtBalance--获取余额', web3.utils.fromWei(result, 'ether'));
                    const amount = web3.utils.fromWei(result, 'ether')
                    console.log(amount);
                    resolve(keepDecimal(amount))
                }).catch((error) => {
                    reject(error)
                    console.error(error); // 处理错误
                });
            })
        )
    },
    getBurBalance({dispatch, commit}, account) {
        dispatch('initBur')
        return new Promise(((resolve, reject) => {
                const web3 = state.web3;
                state.burContract.methods.balanceOf(account).call().then((result) => {
                    console.log('getBurBalance--获取余额', web3.utils.fromWei(result, 'ether'));
                    const amount = web3.utils.fromWei(result, 'ether')
                    console.log(amount);
                    resolve(keepDecimal(amount))
                }).catch((error) => {
                    reject(error)
                    console.error(error); // 处理错误
                });
            })
        )
    },
    //授权交易
    authorizedTransaction({dispatch, commit}, {account, number}) {
        dispatch('initUser')
        return new Promise(((resolve, reject) => {
                const web3 = state.web3;
                const amountToApprove = web3.utils.toHex(web3.utils.toWei(number, 'ether'));
                state.userContract.methods.approve(state.bankParams.pledge.address, amountToApprove)
                    .send({from: account})
                    .then(res => {
                        //授权成功
                        console.log(res);
                        resolve(res)

                    })
                    .catch(err => {
                        console.log(err);
                        reject(err)

                    })
            })
        )
    },

    //闪兑授权
    authorizedTransactionSwap({dispatch, commit}, {account, number}) {
        dispatch('initUsdt')
        return new Promise(((resolve, reject) => {
                const web3 = state.web3;
                const amountToApprove = web3.utils.toHex(web3.utils.toWei(number, 'ether'));
                state.usdtContract.methods.approve(state.bankParams.swap.address, amountToApprove)
                    .send({from: account})
                    .then(res => {
                        //授权成功
                        console.log(res);
                        resolve(res)

                    })
                    .catch(err => {
                        console.log(err);
                        reject(err)

                    })
            })
        )
    },

    // 授权BUR
    authorizedTransactionSwapBur({dispatch, commit}, {account, number}) {
        dispatch('initBur')
        return new Promise(((resolve, reject) => {
                const web3 = state.web3;
                const amountToApprove = web3.utils.toHex(web3.utils.toWei(number, 'ether'));
                state.burContract.methods.approve(state.bankParams.pledge.address, amountToApprove)
                    .send({from: account})
                    .then(res => {
                        //授权成功
                        console.log(res);
                        resolve(res)

                    })
                    .catch(err => {
                        console.log(err);
                        reject(err)

                    })
            })
        )
    },

    usdtSwapBur({dispatch, commit}, {account, number}) {
        dispatch('initSwap')
        return new Promise(((resolve, reject) => {
                const web3 = state.web3;
                const amountToApprove = web3.utils.toHex(web3.utils.toWei(number, 'ether'));
                state.swapContract.methods.swapToken(amountToApprove)
                    .send({from: account})
                    .then(res => {
                        //闪兑成功
                        console.log('闪兑BUR-------',res)
                        resolve(res)

                    })
                    .catch(err => {
                        console.log(err);
                        reject(err)

                    })
            })
        )
    },

    swapTokenBur({dispatch, commit}, {account, number}) {
        dispatch('initBank')
        return new Promise(((resolve, reject) => {
                const web3 = state.web3;
                const amountToApprove = web3.utils.toHex(web3.utils.toWei(number, 'ether'));
                state.bankContract.methods.swapTokenBur(amountToApprove)
                    .send({from: account})
                    .then(res => {
                        //闪兑成功
                        console.log('BUR兑换成聚合资产-------',res)
                        resolve(res)

                    })
                    .catch(err => {
                        console.log(err);
                        reject(err)

                    })
            })
        )
    },
    //获取绑定状态
    getBinds({dispatch, commit}, account) {
        dispatch('initBank')
        return new Promise(((resolve, reject) => {
                const web3 = state.web3;
                state.bankContract.methods.binds(account).call().then((result) => {
                    console.log("111=>", result);
                    resolve(result)
                }).catch((error) => {
                    reject(error)
                    console.error(error); // 处理错误
                });
            })
        )
    },
    //绑定用户
    bindInvitation({dispatch, commit}, {'account': account, 'invitationCode': invitationCode}) {
        dispatch('initBank')
        return new Promise(((resolve, reject) => {
                const web3 = state.web3;
                const gasLimit = web3.utils.toHex(300000);
                const gasPrice = web3.utils.toHex(web3.utils.toWei('3', 'gwei'));
                // const contract = new web3.eth.Contract(bankAbi, bankApproveCont)
                state.bankContract.methods.bindInvitation(invitationCode)
                    .send({from: account, gasLimit, gasPrice})
                    .then(result => {
                        console.log(result);
                        resolve(result)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        )
    }


}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
