// 计算出时间戳的具体数据：比如将85400转化为 n天n时n分n秒
export function formateTimeStamp(timeStamp) {
    var day;
    var hour;
    var min;
    var seconds;

    day = parseInt(timeStamp / (24 * 60 * 60)) // 计算整数天数
    var afterDay = timeStamp - day * 24 * 60 * 60 // 取得算出天数后剩余的秒数
    hour = parseInt(afterDay / (60 * 60)) // 计算整数小时数
    var afterHour = timeStamp - day * 24 * 60 * 60 - hour * 60 * 60 // 取得算出小时数后剩余的秒数
    min = parseInt(afterHour / 60) // 计算整数分
    seconds = parseInt(timeStamp - day * 24 * 60 * 60 - hour * 60 * 60 - min * 60) // 取得算出分后剩余的秒数

    if (day < 10) {
        day = '0' + day;
    }

    if (hour < 10) {
        hour = '0' + hour
    }

    if (min < 10) {
        min = '0' + min;
    }

    if (seconds < 10) {
        seconds = '0' + seconds;
    }

    const restStamp = {
        day: day,
        hour: hour,
        min: min,
        seconds: seconds
    }
    return restStamp
}

//保留小数位
export function keepDecimal(num) {
    const parts = num.split('.');
    if (parts.length > 1) {
        let decimalPart = parts[1];
        if (parts[1].length > 6) {
            decimalPart = parts[1].slice(0, 6);
        }
        return parts[0] + "." + decimalPart
    }
    return num;
}

export function levelPeriod() {
    return {
        15: {
            'days': 15,
            'rate': '0.1',
            'extra_rate': '0.012',
            'weight': '1.2',
        },
        30: {
            'days': 30,
            'rate': '0.22',
            'extra_rate': '0.015',
            'weight': '1.5',
        },
        90: {
            'days': 90,
            'rate': '0.7',
            'extra_rate': '0.02',
            'weight': '2',
        },
        180: {
            'days': 180,
            'rate': '1.45',
            'extra_rate': '0.0228',
            'weight': '2.28',
        },
        360: {
            'days': 360,
            'rate': '3',
            'extra_rate': '0.03',
            'weight': '3',
        },
        720: {
            'days': 720,
            'rate': '7',
            'extra_rate': '0.05',
            'weight': '5',
        },
    }
}
