// zh_TW 繁体中文
module.exports = {
    bring2:
        {
            "stayTuned": "敬請期待",
            "noMore": "沒有更多了",
            "loading": "加載中",
            "gpt": "貝靈GPT",
            "search_time_arr": {
                "bz": "近7天",
                "by": "近1個月",
                "bj": "近3個月",
                "jyn": "近一年"
            },
            "allType": "全部類型",
            "nav": {
                "walletAddress": "當前錢包地址",
                "home": "首頁",
                "coin": "AI算力",
                "community": "社區",
                "property": "資產",
                "website": "官網",
                "destroyMining": "銷毀挖礦",
                "power": "我的算力",
                "exchange": "閃兌",
                "invite": "邀請",
                "knowledge": "知識庫",
                "whitePaper": "白皮書",
                "signedIn": "已簽到",
                "signIn": "簽到",
                "received": "已領取",
                "signInText": "每次簽到可獲得1BSDT，可用於抵扣購買算力的支付。 持續100天，即滿100BSDT可免費提出",
                "plan": "貝靈養成計畫",
                "prompt": "完成問答即可簽到完成",
                "correctAnswer": "回答正確",
                "wrongAnswer": "回答錯誤",
                "toLearn": "去貝靈知識庫學習",
                "tomorrow": "期待明日再見",
                "obtain": "獲得",
                "select": "選擇"
            },
            "home": {
                "aiPower": "貝靈AI算力",
                "tripleExpansion": "三倍膨脹財富盛宴",
                "destroyMining": "銷毀挖礦",
                "tripleMining": "三重銷毀財富增值",
                "aiDialogue": "AI對話一切皆有答案",
                "digitalHuman": "數字人",
                "website": "官網",
                "view_details": "查看詳情",
            },
            "algorithm": {
                "totalInvestment": "總投入",
                "totalPower": "總算力",
                "totallIncome": "總收入",
                "joinNow": "立即參與",
                "joinNowText": "歡迎加入BURNING計算能力模型。 您需要至少投資100 BSDT/USDT才能參與",
                "join": "加入",
                "welcomeJoin": "歡迎加入BURING！",
                "enter": "請輸入您要轉入的",
                "availableBalance": "可用餘額",
                "getPower": "恭喜獲得算力",
                "determine": "確定",
                "balance": "餘額不足",
                "pleaseRecharge": "請充值餘額後再購買算力",
                "toRecharge": "去充值",
                "participationRecord": "參與記錄",
                "coin": "幣種",
                "type": "類型",
                "purchasingPower": "購買算力",
                "power": "算力",
                "date": "日期"
            },
            "mining": {
                "totalInvestment": "總投入",
                "initialLimit": "BEL首發額度",
                "totalRevenue": "總收益",
                "welcomeText": "歡迎加入銷毀挖礦",
                "pleaseNum": "請輸入您要轉入的數量",
                "destroyCurrency": "銷毀幣種",
                "balance": "餘額",
                "fillQuantity": "填寫數量",
                "participateMining": "參與挖礦",
                "successfully": "銷毀挖礦成功",
                "participationRecord": "參與記錄",
                "inProgress": "進行中",
                "completed": "已完成",
                "type": "類型",
                "destroyMining": "銷毀挖礦",
                "date": "日期",
                "detailedRecords": "詳細記錄",
                "getPower": "預計獲得算力",
                "tips": "提示",
                "determine": "確定",
                "insufficient": "餘額不足"
            },
            "power": {
                "participationRecord": "參與記錄",
                "staticOutput": "靜態產出",
                "dynamicAcceleration": "動態加速",
                "detailedRecords": "詳細記錄",
                "invitee": "被邀請人",
                "stable_coin": "獲得算力",
                "created_at": "獲取時間",
                "total_amount": "總算力",
                "total_power": "剩餘算力"
            },
            "team": {
                "community": "社區",
                "team_level": "我的社區等級",
                "my_rectangle": "我的業績",
                "team_rectangle": "社區業績",
                "my_power": "我的總算力",
                "community_power": "社區總算力",
                "direct_push_count": "我的直推",
                "performance": "按業績",
                "byTime": "按時間",
                "invite_count": "邀請人數",
                "total_count": "總業績",
                "created_at": "加入時間"
            },
            "property": {
                "address": "當前錢包地址",
                "total": "總資產",
                "myTotal": "我的資產",
                "recharge": "充幣",
                "propose": "提出",
                "transferAccounts": "轉帳",
                "outDetails": "轉出明細",
                "balance": "可用餘額",
                "outQuantity": "轉出數量",
                "walletAdd": "提出地址",
                "commission": "手續費",
                "actualReceipt": "實際到賬",
                "proposeConfirmation": "提出確認",
                "coin": "提出幣種",
                "confirmProposal": "確定提出",
                "proposeRecords": "提出記錄",
                "homepage": "返回首頁",
                "viewRecords": "查看記錄",
                "transferDetails": "轉入明細",
                "transferredQuantity": "轉入數量",
                "toAddress": "轉入地址",
                "filling": "充入",
                "chargeRecord": "充入記錄"
            },
            "convert": {
                "exchangeCurrency": "兌出幣種",
                "balance": "餘額",
                "proposedAmount": "填寫提出金額",
                "currencyConversion": "兌入幣種",
                "commission": "手續費",
                "feeRatio": "手續費比例",
                "exchange": "兌換",
                "exchangeRecords": "兌換記錄",
                "completed": "已完成",
                "date": "時間"
            },
            "invite": {
                "myInviteUrl": "我的邀請連結",
                "myInvite": "我的邀請人",
                "generateImages": "生成圖片",
                "copyLink": "複製連結"
            },
            "knowledge": {
                "welcomeTo": "歡迎來到",
                "text2": "貝靈知識庫",
                "text3": "知識庫幫助您完成簽到獲得BSDT"
            },
            "gptPage":{
                "welcomeTo": "歡迎來到貝靈GPT，有任何問題都可以找到我聊聊哦~",
                "pleaseQuestion": "請輸入您的問題"
            }
        },
    bring: {
        index: {
            start: '啟動'
        },
        loadMore: '加載更多',
        success: '操作成功',
        error: '操作失敗',
        promptNum: '請輸入正確的數量',
        hint: '合約地址，僅用展示，切勿充值',
        insufficientBalance: '曠工費或餘額不足',
        home: {
            myTotalDeposit: '我的總入金',
            myComputingPowerValue: '我的算力值',
            usdtRevenueValue: 'USDT 收益值',
            bsdRevenueValue: 'BSDT 收益值',
            invest: '投入',
            tokenWithdrawal: '提取代幣',
            available: '可領取',
            withdrawal: '提取',
            resumption: '複投',

            detail: '明細',
            quantity: '數量',
            coin: '幣種',
            power: '算力',
            item: '時間',
        },
        nav: {
            coinage: '鑄幣',
            popularization: '推廣',
            exchange: '兌換',
            asset: '資產',
            wallet: '錢包',
            logOut: '註銷',
        },
        invite: {
            personalPerformance: '個人收益',
            teamPerformance: '團隊業績',
            myInviterAddress: '我的邀請人地址',
            myInvitationLink: '我的邀請鏈接',
            myDirectInvitationData: '我的直邀數據',
            myPower: '我的邀請算力',
            computingPower: '每日釋放算力',
            releasePower: '我的釋放總算力',
            binding: '綁定',
            address: '地址',
            power: '算力',
            invitingPower: '邀請算力',
            myComputingPower: '我的當前總算力',
            teamRevenueL: '團隊入金',
            teamLevel: '團隊VIP等級',
            promptCode: '請輸入正確邀請碼',

            staticProfit: '靜態收益',
            helpProfit: '幫扶收益',
            directPushProfit: '直推收益',
            manageProfit: '管理收益',
            levelProfit: '平級收益',
            teamProfit: '團隊收益',
            inviterAddress: '邀請地址',
            item: '時間',
            direct_push_total: '我的直推總入金',
            team_power_total: '團隊總算力',
            team_total_usdt: '團隊總入金',
            team_total_bsdt: '團隊總入金',
            team_level_count: '團隊平級',
            region: '大區',
            smallArea: '小區',
            record: '記錄',
        },
        exchange: {
            notYetOpen: '暫未開放',
            contractAddress: '合約地址',
            exchange: '兌換',
            exchangeRecords: '兌換記錄',
            exchangeSuccess: '兌換成功',
            desc: '類型',
            number: '數量',
            tiem: '時間',
            copy: '複製',
        },
        conis: {
            withdraw: '提現',
            recharge: '充值',
            record: '記錄',
            platform_address: '充值地址',
            type_desc: '類目',
            amount: '金額',
            free: '手續費',
            state_desc: '狀態',
            within: '',
            // within: '15min內到賬',
            clickRecharge: '一鍵充值',

            staticOutput: '24小時靜態產出明細',
            dynamicOutput: '24小時動態產出明細',
            assistanceReward: '幫扶獎勵明細',
        }
    },
    nav: {
        title: 'Aave V2',
        user_name: 'Aave V2',
        home: '首頁',
        account: '帳號',
        activity: '活動',
        history: '交易歷史',
        referral: '邀請好友',
        help: '幫助中心',
        whitePaper: '白皮書',
        language: '選擇語言',
    },
    index: {
        verifier_map: 'ETH驗證者地圖',
        total_ore: '總產出 (ETH)',
        today_eth: '今日盈利 (ETH)',
        effective_node: '有效節點',
        user_revenue: '用戶收入',
        peoples: '參與者',
        receipt_voucher: '接收憑證',
        join_now: '立即加入節點 開始挖礦',
        receive: '接收',
        view_details: '查看詳細資訊',
        participate_activities: '參加活動',
        earn_eth: '賺 ETH',
        user_output: '用戶輸出',
        address: '地址',
        number: '數量',
        cooperative_partner: '合作夥伴',
        confirm_view: '確認查看',
        notice: '公告',
        close: '關閉',
    },
    account: {
        account_number: '帳號',
        wallet_balance: '錢包餘額 (USDT)',
        exchangeable: '可交換的 (ETH)',
        exchange: '兌換',
        withdrawal: '提現',
        on_chain_balance: '鏈上餘額 (USDT)',
        total_output: '總產出 (ETH)',
        mining_reward: '挖礦獎勵',
        contract_award: '合約獎勵',
        quantity: '數量',
        time: '時間',
        loading: '加載中...',
        no_more: '沒有更多了',
        load_more: '加載更多',
        event_rewards: '活動獎勵',
        view_details: '查看詳細資訊',
    },
    withdraw: {
        exchange: '兌換',
        all: '全部',
        eth_to_usdt: '將ETH兌換為USDT',
        enter_quantity: '請輸入數量',
        exchange_success: '兌換成功',
    },
    exchange: {
        withdrawal: '提現',
        all: '全部',
        mini_amount: ' 最低提款金額為',
        payment_date: ' 將在24小時內發送到錢包地址',
        withdrawal_but: '提現',
        withdrawal_success: '提現成功',
    },
    activity: {
        title: '活動',
        no_data: '暫無數據',
        threshold: 'USDT門檻:',
        reward_amount: '獎勵數量:',
        distribution_period: '派發週期(天):',
        term_validity: '有效期:',
        participate_activities: '參加活動',
        absent: '未參加',
        progress: '進行中',
        cancel: '已取消',
        settled: '已結算',
        standard: '標準',
        view_details: '查看詳情',
        confirm: '待確認',
        success: '參加成功',
        cancel_but: '取消',
        accept_but: '接受',
        apply_rewards: '申請獎勵',
        apply_info: ' ETH將在質押成功後的第三天發送到您的帳號',
        received_term_validity: '收到有效時間',
        day: '天',
    },
    history: {
        title: '交易歷史',
        exchange: '兌換',
        exchange_details: '兌換明細',
        eth_num: 'ETH數量',
        usdt_num: 'USDT數量',
        free_amount: '手續費(USDT)',
        withdrawal_details: '提現明細',
        success: '成功',
        under_review: '稽核中',
        fail: '失敗',
        amount: '數量：',
        apply_amount: '實際到賬：',
        free: '手續費： ',
        mining: '挖礦 ',
        time: '時間 ',
        mining_amount: '數量 ',
        activity_reward: '活動獎勵 ',
        pledge_reward: '質押獎勵 ',
        no_data: '暫無數據 ',
        received: '已領取 ',
    },
    referral: {
        invite_friends: '邀請好友',
        earn_commission: '賺傭金',
        send_invitation_link: '發送您的邀請連結，好友將通過您的連結加入節點',
        invitation_code: '我的邀請碼',
        share_link: '我的分享連結',
        today_commission: '今日收入 (ETH)',
        total_commission: '總傭金 (ETH)',
        commission_withdrawal_limit: '可轉出 (ETH)',
        peoples_all: '團隊人數',
        peoples_one: '直接發起人',
        commission_transfer: '傭金轉移',
        transfer_account: '將傭金收入轉入帳戶錢包',
        transfer_all: '全部轉移',
        transfer: '轉移',
        L1: '等級一',
        L2: '等級二',
        L3: '等級三',
        transfer_success: '轉移成功',
        enter_quantity: '請輸入數量',
        overstep_quantity: '超出可轉移傭金',
        mini_quantity: '最低轉帳金額至少為',
        copy_succee: '複製成功',
        view_rules: '查看規則',
        team_rules: '團隊佣金規則',
        rules1: '1. 當你邀請2個以上的朋友參與挖礦，且每個人參與挖礦的USDT金額在USDT500.00-3000.00之間時，則每個邀請1人可獲得0.1ETH。',
        rules2: '2. 當你邀請超過5位好友參與挖礦，且每個人參與挖礦的USDT金額在USDT3000.00-10000.00之間時，則每個邀請1人可獲得0.2ETH。',
        rules3: '3.當你邀請超過8位好友參與挖礦，且每個人參與挖礦的USDT金額在USDT10000.00-50000.00之間時，則每個邀請1人可獲得0.3ETH。',
        rules4: '4、當你邀請超過10位好友參與挖礦，且每個人參與挖礦的USDT金額在USDT50000.00-200000.00之間時，則每個邀請1人可獲得0.5ETH。',
        rules5: '您可以在推薦鏈接或側菜單中的分享按鈕中收到邀請鏈接。具體活動規則及獎勵請聯繫客服!',
    },
    commission: {
        title: '傭金明細',
        all: '所有',
        level1: '等級一',
        level2: '等級二',
        level3: '等級三',
        no_data: '暫無數據 ',
    },
    help: {
        title: '幫助中心',
        no_data: '暫無數據 ',
    },
    language: {
        title: '選擇語言',
        determine: '確定 ',
    },
    remind: {
        tips: '提示',
        text1: '在錢包請訪問',
        text2: '您目前正在訪問一個去中心化網站，請在錢包應用程序中打開它',
        text3: '複製URL',
        text4: '複製成功',
    },
    details: {
        title: '已參與流動性挖礦',
        rule: '規則',
        reward_history: '獎勵歷史',
        explain: '說明',
        explain_data: '獲取節點憑證後成爲流動性提供者，錢包中存儲USDT將自動提供流動性獲取ETH獎勵，獎勵每6小時產生一次',
        total_rewards: '總獎勵 (ETH)',
        today_eth: '今日獎勵 (ETH)',
        received: '已收到',
        rule_data: 'Aave V2矿池將根據錢包中Tether USDT的數量提供計算能力',
    },
    activity_details: {
        title: '您將接收高達',
        reward: '獎勵',
        end: '結束',
        standard: '標準',
        mortgaged: '已抵押',
        pledge_reward: '質押獎勵',
        not_up: '您的按揭金額未達標。',
        up_more: '充值更多',
        get_rewards: '獲得獎勵。',
        received: '已收到',
        have_hand: '進行中. 已領取',
        not_claimed: '未領取',
        apply_participation: '申請參加',
        end_activity: '活動結束。',
        day: '天',
        hour: '小時',
        min: '分鐘',
        seconds: '秒',
        collected: '已收集',
    },
    "流量": "流量",
    "数量": "數量",
    "分享": "分享",
    "裂变": "裂變",
    "裂变设置": "裂變設定",
    "裂变绑定记录": "裂變綁定記錄",
    "银行个人中心": "銀行個人中心",
    "数字人个人中心": "數字人個人中心",
    "静态释放": "靜態釋放",
    "购买模型套餐": "購買模型套餐",
    "购买cdk": "購買cdk",
    "管理操作": "管理操作",
    "养草值记录": "養草值記錄",
    "参数系数": "參數係數",
    "杠杆倍数": "杠杆倍數",
    "对碰比例": "對碰比例",
    "兑换中...": "兌換中...",
    "禁止绑定": "禁止綁定",
    "邀请总收益": "邀請總收益",
    "活期存储不能小于100BUR": "活期存儲不能小於100BUR",
    "基本利息": "基本利息",
}
