// ja 日语
module.exports = {
    "敬请期待": "お楽しみに",
    "没有更多了": "これ以上ない",
    "加载中": "ロード中",
    "钱包地址": "アドレス",
    "首页": "メイン",
    "银行": "バンク",
    "数字人": "メタマン",
    "游戏": "ゲーム",
    "邀请": "招待",
    "套餐": "コース",
    "收益": "収益",
    "社区": "コミュニティ",
    "个人中心": "プロフィール",
    "退出登录": "コネクトを切断する",
    "请绑定钱包": "ウォレットコネクト",
    "娱乐": "レクリエーション",
    "生态": "せいたい",
    "个人资产": "個人資産",
    "总资产": "総資産",
    "开户": "口座を開設する",
    "元交易": "取引",
    "元储存": "入金",
    "数据契约": "データ契約",
    "开户成功": "口座開設に成功しました",
    "我的邀请人": "私が招待した人",
    "确定": "確認",
    "绑定成功": "バインド成功",
    "请输入正确邀请码": "正しい招待コードを入力してください",
    "银行提示": "ご注意：「Burning Metaman」の招待リンクは「Burning Metaman」のみが使用できます。また、「Burning Kingdom」のアプリはそれぞれ独自の招待リンクがあります。",
    "闪兑": "交換",
    "兑换的币种直接进入平台个人中心": "プロフィールに入って交換した貨幣をチェックする",
    "兑换": "交換",
    "兑出币种": "貨幣交換",
    "可用": "使用可能の",
    "全部": "マックス",
    "兑入币种": "貨幣交換",
    "请输入正确的数量": "正しい数量を入力してください",
    "请输入": "入力してください",
    "我的资产": "個人資産",
    "种草值": "木植えポイント",
    "养草值": "成長ポイント",
    "拔草值": "草抜きポイント",
    "特奖值": "ボーナスポイント",
    "充值": "を補充",
    "提出": "お金を引き出す",
    "提出成功": "成功",
    "提出记录": "記録",
    "购买记录": "記録",
    "购买": "購入する",
    "手续费": "手数料",
    "日期": "日時",
    "转入地址": "アドレス",
    "充值币种": "貨幣",
    "充值成功": "成功",
    "充值记录": "記録",
    "我的邀请码": "私の招待コード",
    "邀请码": "の招待コード",
    "生成图片": "画像を生成",
    "复制链接": "リンクのコピー",
    "复制成功": "コピー成功",
    "扫一扫": "スキャンして理解する",
    "消息中心": "公告",
    "查看详情": "詳細をチェック",
    "数字人提示": "ご注意：「Burning Metaman」の招待リンクは「Burning Metaman」のみが使用できます。また、「Burning Kingdom」のアプリはそれぞれ独自の招待リンクがあります。",
    "激活": "アクティブ化",
    "我的": "私の",
    "已使用": "使用済み",
    "未使用": "未使用",
    "失败": "失敗",
    "审核中": "検討中",
    "编号": "番号付け",
    "激活成功": "成功",
    "请输入正确的CDK": "正しいものを入力してください CDK",
    "记录": "記録",
    "已完成": "完成",
    "未完成": "未完了",
    "兑换套餐": "コースを選ぶ",
    "我的业绩": "私の業績",
    "社区业绩": "コミュニティ業績",
    "大区业绩": "区域業績",
    "小区总业绩": "コミュニティ総業績",
    "直推": "おすすめ",
    "对碰": "コンペティション",
    "搜索": "検索",
    "搜索UID编号": "検索UID",
    "历史搜索": "履歴検索",
    "下级": "初級",
    "兑换成功": "成功",
    "闪兑记录": "交換記録",
    "交易服务费": "サービス料",
    "总养草值": "総成長ポイント",
    "已释放养草值": "獲得した成長ポイント",
    "未释放养草值": "まだ獲得できる成長ポイント",
    "已释放特奖值": "獲得したボーナスポイント",
    "未释放特奖值": "まだ獲得できるボーナスポイント",
    "静态": "安定収益",
    "动态": "動態収益",
    "特殊": "その他",
    "详细记录": "詳細記録",
    "近一周": "週",
    "近一个月": "一ヶ月",
    "近三个月": "三ヶ月",
    "近一年": "1年",
    "加入": "参加",
    "复投": "ふくとう",
    "欢迎获得西欧特权": "축하합니다! 서유럽에 합류했습니다!",
    "支付": "支払い",
    "余额": "残高",
    "购买套餐将直接获得算力,购买CDK将获得激活码用于激活， 不会获得算力。": "パッケージを購入した場合はコンピューティング パワーを直接受け取りますが、CDK を購入した場合はアクティベーション用のアクティベーション コードを受け取りますが、コンピューティング パワーは受け取りません。",
    "可获得": "使用可能分",
    "恭喜获得": "おめでとう！あなたは成長ポイント",
    "复制": "コピー",
    "请选择产品": "製品を選択してください",
    "转账": "振り替え",
    "对碰设置": "コンテストセッティング",
    "类型": "種類",
    "待设置关系ID": "関連性をセッティングする必要があるID",
    "每日24点自动绑定": "毎日の24時で自動的に連携する",
    "暂无数据": "データなし",
    "折叠": "折り畳み",
    "展开": "てんかい",
    "设置关系": "関連性をセッティング",
    "非关系账户无法绑定": "関連性のないアカウントは連携することができません",
    "绑定的上级用户": "バインドされた親ユーザー",
    "上级": "上級",
    "是否": "かどうか",
    "绑定到": "バインド＃バインド＃",
    "取消": "キャンセル",
    "请输入正确的ID": "正しいIDを入力してください",
    "对碰绑定记录": "記録",
    "转账明细": "詳細記録",
    "非关系账户无法转账": "非関係口座は振り替えることができません",
    "转账成功": "成功",
    "向": "向",
    "转入": "総計",
    "请输入正确的UID": "正しいUIDを入力してください",
    "转账记录": "記録",
    "数额": "数量",
    "操作失败": '操作に失敗しました',
    "矿工费或余额不足": 'マイニング料金または残高不足',
    "资产": '資産',
    "划转": "交換",
    "转入1": "転入",
    "转出": "転出",
    "划转成功": "成功",
    "密码": "パスワード",
    "修改密码": "パスワードを変更",
    "请设置密码": "パスワードを設定してください",
    "原密码": "元のパスワード",
    "设置密码": "パスワードを設定",
    "再次输入密码": "パスワードを再入力してください",
    "请输入原密码": "元のパスワードを入力してください",
    "如忘记密码请联系管理员": "パスワードを忘れた場合は、管理者に連絡してください",
    "请输入6位密码": "6桁のパスワードを入力してください",
    "两次密码不一致": "2 つのパスワードは矛盾しています",
    "设置成功": "正常に設定されました",
    "流量": "りゅうりょう",
    "数量": "数量",
    "分享": "分かち合う",
    "裂变": "ぶんれつ",
    "裂变设置": "スプリット設定",
    "裂变绑定记录": "かくぶんれつバインディングきろく",
    "银行个人中心": "銀行個人センター",
    "数字人个人中心": "ディジタル人",
    "静态释放": "せいてきかいほう",
    "购买模型套餐": "モデルセットを購入",
    "购买cdk": "cdkの購入",
    "管理操作": "管理アクション",
    "请先激活账户，再获取邀请码": "まずアカウントを開設してから招待コードを取得してください",
    "请输入密码": "パスワードを入力してください",
    "养草值记录": "養草値記録",
    "去开户": "口座開設に行く",
    "参数系数": "パラメータ係数",
    "杠杆倍数": "てこ倍数",
    "对碰比例": "せっしょくひれい",

    "更多期待": "期待以上",
    "BUINING BANK 核心优势": "BUINING BANKの主要な利点",
    "体系优势：": "システムの利点：",
    "全球顶级的资深银行家团队构建Al智能体系，引领全球银行进入生态4.0财富平权，用算力去战胜智力，将财富主动权交还给全球公民。": "世界トップクラスの経験豊富な銀行家チームがAlインテリジェントシステムを構築し、グローバルな銀行を生態系4.0の富の平等に導き、計算力で知力に打ち勝ち、富の主導権を世界の市民に返します。",
    "技术优势：": "技術の利点：",
    "自建人工智能理财模型，平均夏普辛26.7，最大回撤6.2%，能做到超高无风险收益比，为储户高息收入保驾护航。智能投保、衍生杠杆交易、最大化复合流动性。": "独自の人工知能ファイナンシャルモデルを構築し、平均シャープレシオ26.7、最大引き戻し6.2％を実現し、超高いリスクなしのリターン比率を提供し、預金者の高利得収入をサポートします。スマート保険、デリバティブレバレッジ取引、最大化された複合流動性。",
    "产品优势：": "製品の利点：",
    "六大契约业务全在链上进行，完全去中心化。一美金就能投资，人人可参与，随进随出。小钱参与活期、大钱放进定期、高回报衍生杠杆，抢占财富先人一步。": "六つの主要な契約ビジネスはすべてチェーン上で実施され、完全に非中央集権化されています。1ドルで投資でき、誰でも参加し、いつでも入退場できます。少ないお金で現在の参加、大金で定期的に投資し、高いリターンの派生レバレッジで富を一歩先に占めます。",
    "邀请链接": "招待リンク",
    "活期存款": "即期預金",
    "定期存款": "定期預金",
    "邀请信息": "招待情報",
    "直推人数": "ダイレクトリファラル",
    "团队规模": "チームの規模",
    "团队资金": "チーム資金",
    "我的邀请地址": "私の招待アドレス",
    "温馨提示：贝灵数字人邀请链接只限于贝灵数字人使用， 贝灵王国各个应用会有独立邀请链接。": "注意：Beiling Digital Manの招待リンクはBeiling Digital Manのみ使用できます。 Beiling Kingdomの各アプリケーションには独自の招待リンクがあります。",
    "潜在客户": "潜在的な顧客",
    "精准客户": "精密な顧客",
    "分行潜在储户": "支店の潜在的な預金者",
    "分行实际储户": "支店の実際の預金者",
    "分行储额(U)": "支店の預金額(U)",
    "分行活期储额": "支店の即期預金額",
    "分行定期储额": "支店の定期預金額",
    "我的邀请收益": "私の招待収益",
    "待领取收益": "未受け取りの収益",
    "领取成功": "正常に受け取りました",
    "领取": "受け取り",
    "邀请收益记录": "招待収益の記録",
    "邀请收益": "招待収益",
    "已领取": "受け取り済み",
    "活期": "即期",
    "定期": "定期",
    "活期总存款/未激活": "総即期預金/非アクティブ",
    "聚合资产": "集約資産",
    "可领取利息": "受取可能な利息",
    "已领取利息": "受け取られた利息",
    "领取利息赎回本金": "利息の受け取りと元本の償還",
    "钱包余额": "ウォレット残高",
    "存储": "ストレージ",
    "储蓄时间": "預金期間",
    "储蓄APY": "預金APY",
    "定期总存款": "総定期預金",
    "基础利息+浮动利息": "基本金利+浮動金利",
    "浮动利息根据套利协议计算利": "浮動金利はアービトラージ契約に基づいて計算されます",
    "我的订单": "私の注文",
    "交易详情": "取引の詳細",
    "保险费用": "保険料",
    "总计": "合計",
    "根据保险协议,本次储蓄将收额外收取储蓄本金的0.5%": "保険契約に基づき、この貯金からは0.5％の追加で預金元本が収集されます。",
    "基础利息": "基本金利",
    "浮动利息": "浮動金利",
    "可用利息": "利用可能な利息",
    "认购金额": "認購額",
    "套餐时间": "パッケージの期間",
    "存储本金": "貯蔵元本",
    "存储数量": "貯蔵数量",
    "选择类型": "タイプの選択",
    "进行中": "進行中",
    "提取利息赎回本金": "利息の受け取りと元本の償還",
    "请注意，由于您没满合约期提前提取本金和利息，您的利息将会减少。本金扣除1%": "ご注意：契約期間が終了する前に元本と利息を引き出すと、利息が減額されます。元本控除は1％です。",
    "抱歉，您的聚合资产不足以完成兑换操作，请确认聚合资产是否足够后再继续操作。": "申し訳ありませんが、集計資産が交換操作を完了するのに十分でない場合は、続行する前に集計資産が十分であるか確認してください。",
    "赎回本金": "元本の償還",
    "提取利息": "利息の引き出し",
    "本金": "元本",
    "扣除本金": "元本控除",
    "余额不足？": "残高不足？",
    "点击此处": "ここをクリック",
    "可赎回数量": "償還可能な数量",
    "平台钱包": "プラットフォームウォレット",
    "银行钱包": "銀行のウォレット",
    "存入成功": "入金成功",
    "兑换中": "両替中です...",
    "禁止绑定": "バインディング禁止です",
    "邀请总收益": "総収益を招きます",
    "活期存储不能小于100BUR": "要求ストレージは100BUR未満ではいけません。",
    "基本利息": "基本金利です",
}
