import Vue from 'vue';
import App from './App';

import {router} from './router';
import store from './store'


import Web3 from 'web3'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n);
require('swiper/dist/css/swiper.css')
import VueAwesomeSwiper from 'vue-awesome-swiper'

Vue.use(VueAwesomeSwiper)
import VueClipBoard from 'vue-clipboard2'

Vue.use(VueClipBoard)


import {
    Button, Form, Field, NavBar, Icon, Swipe, SwipeItem, Divider, Popup,
    Tab, Tabs, Overlay, Cell, CellGroup, RadioGroup, Radio,
    Loading,
    Notify,
    ActionSheet,
    Toast,
    Circle,
    PullRefresh,
    Checkbox,
    List, Dialog
} from 'vant';

Vue.use(Button)
Vue.use(Form)
Vue.use(Field)
Vue.use(Icon)
Vue.use(NavBar)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Divider)
Vue.use(Popup)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Overlay)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Loading)
Vue.use(Notify)
Vue.use(ActionSheet)
Vue.use(Circle)
Vue.use(List)
Vue.use(Dialog)
Vue.use(Toast)
Vue.use(PullRefresh)
Vue.use(Checkbox)

Vue.prototype.Web3 = Web3

/**
 * 限制输入小数
 * decimal：限制小数位。0：不允许输入小数点；-1：不限制；其他正整数。默认不限制。
 * maxLength：限制最大长度。包含小数位和负号。默认值255
 **/
Vue.prototype.prohibitNegative = function (value,decimal=-1, maxLength = 255) {
    if(!value){
        return value;
    }
    if(value=='.' || value=='。'){
        return '';
    }
    value=value.replace("。",'.');

    //限制小数位
    if(decimal>0){
        let regex=new RegExp(`^\\d+(?:\\.\\d{0,${decimal}})?`,"g");
        value = (value && value.match(regex)[0]) || null;
    }
    //限制长度
    if(maxLength > 0 && value && value.length >= maxLength){
        value = value.slice(0,maxLength);
    }
    return value;
}


//多语言实例
const i18n = new VueI18n({
    locale: (function () {
        if (localStorage.getItem('lang')) {
            return localStorage.getItem('lang')
        }
        return 'en'
    }()),
    messages: {
        'en': require('./assets/language/en'), //英文语言包
        'ja': require('./assets/language/ja'), //日语
        'ko': require('./assets/language/ko'), //韩语
        'zh_CN': require('./assets/language/zh_CN'), //简体中文
        'zh_TW': require('./assets/language/zh_TW'), //繁体中文
    }
})

new Vue({
    router,
    store,
    el: '#app',
    i18n,
    render: h => h(App)
});
