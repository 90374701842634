// en 英文
module.exports = {
    "敬请期待": "Stay tuned",
    "没有更多了": "There's no more",
    "加载中": "Loading",
    "钱包地址": "Address",
    "首页": "Main",
    "银行": "Bank",
    "数字人": "BURNING RWA",
    "游戏": "Gamefi",
    "邀请": "Invitation",
    "套餐": "Combo",
    "收益": "Revenue",
    "社区": "Community",
    "个人中心": "Profile",
    "退出登录": "Disconnect",
    "请绑定钱包": "Connect wallet",
    "娱乐": "Recreation",
    "生态": "Ecology",
    "个人资产": "personal assets",
    "总资产": "Total assets",
    "开户": "inactivated",
    "元交易": "Trade",
    "元储存": "Debit",
    "数据契约": "Data Contract",
    "开户成功": "Account opened successfully",
    "我的邀请人": "my inviter",
    "确定": "Confirm",
    "绑定成功": "Binding successful",
    "请输入正确邀请码": "Please enter the correct invitation code",
    "银行提示": "Warm reminder: The invitation link for Burning Metaman is only available to Burning Metaman. Each application of Burning Kingdom will have independent invitation links.",
    "闪兑": "Swap",
    "兑换的币种直接进入平台个人中心": "Please enter the profile to view the currencies that have been converted",
    "兑换": "exchang",
    "兑出币种": "Redeem currency",
    "可用": "Balance",
    "全部": "Max",
    "兑入币种": "Redeem currency",
    "请输入正确的数量": "Please enter the correct quantity",
    "请输入": "please enter",
    "我的资产": "personal assets",
    "种草值": "Planting Point",
    "养草值": "Growth Point",
    "拔草值": "Harvest Point",
    "特奖值": "Bonus Point",
    "充值": "Recharge",
    "提出": "withdraw",
    "提出成功": "success",
    "提出记录": "Record",
    "购买记录": "Record",
    "购买": "purchase",
    "手续费": "Service fee",
    "日期": "Date",
    "转入地址": "Address",
    "充值币种": "Currency",
    "充值成功": "success",
    "充值记录": "Record",
    "我的邀请码": "My Invitation Code",
    "邀请码": "Invitation code",
    "生成图片": "Images",
    "复制链接": "copy link",
    "复制成功": "Replicating Success",
    "扫一扫": "Scan to learn more",
    "消息中心": "Announcement",
    "查看详情": "More details",
    "数字人提示": "Warm reminder: The invitation link for Burning Metaman is only available to Burning Metaman. Each application of Burning Kingdom will have independent invitation links.",
    "激活": "activation",
    "我的": "my",
    "已使用": "used",
    "未使用": "not used",
    "编号": "code",
    "激活成功": "success",
    "请输入正确的CDK": "Please enter the correct CDK",
    "记录": "Record",
    "已完成": "Completed",
    "未完成": "Incomplete",
    "失败": "Fail",
    "审核中": "Under review",
    "兑换套餐": "Redeem combo",
    "我的业绩": "my performance",
    "社区业绩": "community performance",
    "大区业绩": "Regional performance",
    "小区总业绩": "Community performance",
    "直推": "direct",
    "对碰": "competition",
    "搜索": "search",
    "搜索UID编号": "search UID",
    "历史搜索": "Historical search",
    "下级": "Subordinate",
    "兑换成功": "success",
    "闪兑记录": "Swap record",
    "交易服务费": "Service Fee",
    "总养草值": "Total growth Point",
    "已释放养草值": "Growth point you got",
    "未释放养草值": "Remaining growth points",
    "已释放特奖值": "Bonus points you got",
    "未释放特奖值": "Remaining bonus points",
    "静态": "Stable Revenue",
    "动态": "Dynamic Revenue",
    "流量": "Flow",
    "数量": "Number",
    "分享": "Share",
    "裂变": "Fission",
    "裂变设置": "Fission setting",
    "裂变绑定记录": "Fission binding record",
    "特殊": "Other",
    "详细记录": "Details",
    "近一周": "Week",
    "近一个月": "One month",
    "近三个月": "Three months",
    "近一年": "One year",
    "加入": "Join",
    "复投": "resubmission",
    "欢迎获得西欧特权": "Congratulations! You have joined Western Europe!",
    "支付": "Pay",
    "余额": "balance",
    "购买套餐将直接获得算力,购买CDK将获得激活码用于激活， 不会获得算力。": "If you purchase a package, you will receive computing power directly. If you purchase a CDK, you will receive an activation code for activation, but you will not receive computing power.",
    "可获得": "Available",
    "恭喜获得": "Congratulation! You have got ",
    "复制": "Copy",
    "请选择产品": "Please select a product",
    "转账": "Transfer",
    "对碰设置": "Contest setting",
    "类型": "Class",
    "待设置关系ID": "Relationship ID to be set",
    "每日24点自动绑定": "Automatically bind at 24:00 every day",
    "暂无数据": "No data available",
    "折叠": "fold",
    "展开": "launch",
    "设置关系": "Set up relationships",
    "非关系账户无法绑定": "Non-relationship accounts cannot be bound",
    "绑定的上级用户": "Bound superior user",
    "上级": "Superior",
    "是否": "Whether",
    "绑定到": "bind to",
    "取消": "Cancel",
    "请输入正确的ID": "Please enter the correct ID",
    "对碰绑定记录": "Record",
    "转账明细": "Details",
    "非关系账户无法转账": "Non related accounts cannot be transferred",
    "转账成功": "success",
    "向": "to",
    "转入": "bur Amount",
    "请输入正确的UID": "Please enter the correct UID",
    "转账记录": "Record",
    "数额": "amount",
    "操作失败": 'operation failed',
    "矿工费或余额不足": 'Mining fee or insufficient balance',
    "资产": ' asset',
    "划转": "Transfer",
    "转入1": "Transfer in",
    "转出": "Transfer out",
    "划转成功": "success",
    "密码": "Password",
    "修改密码": "Change Password",
    "请设置密码": "Please set a password",
    "原密码": "Original password",
    "设置密码": "Set Password",
    "再次输入密码": "Enter password again",
    "请输入原密码": "Please enter the original password",
    "如忘记密码请联系管理员": "If you forget your password, please contact the administrator",
    "请输入6位密码": "Please enter a 6-digit password",
    "两次密码不一致": "Two passwords are inconsistent",
    "设置成功": "Set successfully",
    "银行个人中心": "Bank Profile",
    "数字人个人中心": "RWA Profile",
    "静态释放": "Static release",
    "购买模型套餐": "Purchase Model Package",
    "购买cdk": "Purchase CDK",
    "管理操作": "Management Operations",
    "请先激活账户，再获取邀请码": "Please activate your account first, and then get the invitation code",
    "请输入密码": "Please enter your password",
    "养草值记录": "Record",
    "去开户": "Inactivated",
    "参数系数": "Parameter coefficient",
    "杠杆倍数": "Leverage ratio",
    "对碰比例": "Collision ratio",

    "更多期待": "More Expectations",
    "BUINING BANK 核心优势": "Core Advantages of BUINING BANK",
    "体系优势：": "System Advantages:",
    "全球顶级的资深银行家团队构建Al智能体系，引领全球银行进入生态4.0财富平权，用算力去战胜智力，将财富主动权交还给全球公民。": "A global team of senior bankers builds an AI intelligent system, leading global banks into the ecosystem 4.0 of wealth equality, using computing power to overcome intelligence and returning the initiative of wealth to global citizens.",
    "技术优势：": "Technological Advantages:",
    "自建人工智能理财模型，平均夏普辛26.7，最大回撤6.2%，能做到超高无风险收益比，为储户高息收入保驾护航。智能投保、衍生杠杆交易、最大化复合流动性。": "Self-built artificial intelligence financial model, average Sharpe ratio of 26.7, maximum drawdown of 6.2%, achieving ultra-high risk-free return ratio, escorting depositors for high-interest income. Intelligent insurance, derivative leverage trading, and maximizing compound liquidity.",
    "产品优势：": "Product Advantages:",
    "六大契约业务全在链上进行，完全去中心化。一美金就能投资，人人可参与，随进随出。小钱参与活期、大钱放进定期、高回报衍生杠杆，抢占财富先人一步。": "All six contract businesses are conducted on the chain, completely decentralized. Invest with just one US dollar, everyone can participate, enter and exit at any time. Small money participates in current, big money goes into fixed-term, high return derivative leverage, seizing the wealth one step ahead.",
    "邀请链接": "Invitation Link",
    "活期存款": "Current Deposit",
    "定期存款": "Fixed-Term Deposit",
    "邀请信息": "Invitation Information",
    "直推人数": "Direct Referrals",
    "团队规模": "Team Size",
    "团队资金": "Team Funds",
    "我的邀请地址": "My Invitation Address",
    "温馨提示：贝灵数字人邀请链接只限于贝灵数字人使用， 贝灵王国各个应用会有独立邀请链接。": "Kind Reminder: The invitation link of Beiling Digital Man is only for the use of Beiling Digital Man. Each application of Beiling Kingdom will have an independent invitation link.",
    "潜在客户": "Potential Customers",
    "精准客户": "Precision Customers",
    "分行潜在储户": "Branch Potential Depositors",
    "分行实际储户": "Branch Actual Depositors",
    "分行储额(U)": "Branch Deposit Amount (U)",
    "分行活期储额": "Branch Current Deposit Amount",
    "分行定期储额": "Branch Fixed-Term Deposit Amount",
    "我的邀请收益": "My Invitation Earnings",
    "待领取收益": "Pending Earnings",
    "领取成功": "Successfully Claimed",
    "领取": "Claim",
    "邀请收益记录": "Invitation Earnings Records",
    "邀请收益": "Invitation Earnings",
    "已领取": "Claimed",
    "活期": "Current",
    "定期": "Fixed-Term",
    "活期总存款/未激活": "Total Current Deposits/Inactive",
    "聚合资产": "Aggregate Assets",
    "可领取利息": "Available Interest",
    "已领取利息": "Claimed Interest",
    "领取利息赎回本金": "Claim Interest Redeem Principal",
    "钱包余额": "Wallet Balance",
    "存储": "Storage",
    "储蓄时间": "Savings Time",
    "储蓄APY": "Savings APY",
    "定期总存款": "Total Fixed-Term Deposits",
    "基础利息+浮动利息": "Base Interest + Floating Interest",
    "浮动利息根据套利协议计算利": "Floating interest is calculated according to the arbitrage agreement",
    "我的订单": "My Orders",
    "交易详情": "Transaction Details",
    "保险费用": "Insurance Fee",
    "总计": "Total",
    "根据保险协议,本次储蓄将收额外收取储蓄本金的0.5%": "According to the insurance agreement, an additional 0.5% of the savings principal will be charged for this savings.",
    "基础利息": "Base Interest",
    "浮动利息": "Floating Interest",
    "可用利息": "Available Interest",
    "认购金额": "Subscription Amount",
    "套餐时间": "Package Time",
    "存储本金": "Storage Principal",
    "存储数量": "Storage Quantity",
    "选择类型": "Select Type",
    "进行中": "In Progress",
    "提取利息赎回本金": "Claim Interest Redeem Principal",
    "请注意，由于您没满合约期提前提取本金和利息，您的利息将会减少。本金扣除1%": "Please note that if you redeem the principal and interest before the end of the contract period, your interest will be reduced. Principal deduction is 1%.",
    "抱歉，您的聚合资产不足以完成兑换操作，请确认聚合资产是否足够后再继续操作。": "Sorry, your aggregated assets are not sufficient to complete the exchange operation. Please confirm if your aggregated assets are sufficient before continuing.",
    "赎回本金": "Redeem Principal",
    "提取利息": "Claim Interest",
    "本金": "Principal",
    "扣除本金": "Deduct Principal",
    "余额不足？": "Insufficient Balance?",
    "点击此处": "Click here",
    "可赎回数量": "Redeemable Quantity",
    "平台钱包": "Platform Wallet",
    "银行钱包": "Bank Wallet",
    "存入成功": "Deposit Successful",
    "兑换中...": "Exchange in progress...",
    "禁止绑定": "Disable binding",
    "邀请总收益": "Invited gross revenue",
    "活期存储不能小于100BUR": "Demand storage cannot be less than 100BUR",
    "基本利息": "Primary interest",

}

