import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [
    {
        path: '*',
        redirect: '/'
    },
    {
        path: '/',
        component: () => import('./view/index/index'),
        meta: {
            title: ''
        }
    },
    {
        path: '/personal',
        component: () => import('./view/index/personal'),
        meta: {
            title: ''
        }
    },
    {
        path: '/rwa',
        component: () => import('./view/rwa/index'),
        meta: {
            title: ''
        }
    },
    {
        path: '/rwa/binding',
        component: () => import('./view/rwa/binding'),
        meta: {
            title: ''
        }
    },
    {
        path: '/rwa/record/:type',
        component: () => import('./view/rwa/record'),
        meta: {
            title: ''
        }
    },
    {
        path: '/rwa/income',
        component: () => import('./view/rwa/income'),
        meta: {
            title: ''
        }
    },
    {
        path: '/rwa/community',
        component: () => import('./view/rwa/community'),
        meta: {
            title: ''
        }
    },
    {
        path: '/rwa/share',
        component: () => import('./view/rwa/share'),
        meta: {
            title: ''
        }
    },
    {
        path: '/rwa/shareImage',
        component: () => import('./view/rwa/shareImage'),
        meta: {
            title: ''
        }
    },
    {
        path: '/rwa/personal',
        component: () => import('./view/rwa/personal'),
        meta: {
            title: ''
        }
    },
    {
        path: '/rwa/transfer',
        component: () => import('./view/rwa/transfer'),
        meta: {
            title: ''
        }
    },
    {
        path: '/rwa/transferRecord',
        component: () => import('./view/rwa/transferRecord'),
        meta: {
            title: ''
        }
    },
    {
        path: '/rwa/recharge',
        component: () => import('./view/rwa/recharge'),
        meta: {
            title: ''
        }
    },
    {
        path: '/rwa/exchange',
        component: () => import('./view/rwa/exchange'),
        meta: {
            title: ''
        }
    },
    {
        path: '/rwa/exchangeRecord/:type',
        component: () => import('./view/rwa/exchangeRecord'),
        meta: {
            title: ''
        }
    },
    {
        path: '/rwa/powerRecord',
        component: () => import('./view/rwa/powerRecord'),
        meta: {
            title: ''
        }
    },
    {
        path: '/rwa/cdk',
        component: () => import('./view/rwa/cdk'),
        meta: {
            title: ''
        }
    },
    {
        path: '/rwa/cdkRecord',
        component: () => import('./view/rwa/cdkRecord'),
        meta: {
            title: ''
        }
    },
    {
        path: '/rwa/relation',
        component: () => import('./view/rwa/relation'),
        meta: {
            title: ''
        }
    },
    {
        path: '/rwa/relationRecord',
        component: () => import('./view/rwa/relationRecord'),
        meta: {
            title: ''
        }
    },
    {
        path: '/bank',
        component: () => import('./view/bank/index'),
        meta: {
            title: ''
        }
    },
    {
        path: '/bank/personal',
        component: () => import('./view/bank/personal'),
        meta: {
            title: ''
        }
    },
    {
        path: '/bank/exchange',
        component: () => import('./view/bank/exchange'),
        meta: {
            title: ''
        }
    },
    {
        path: '/bank/burExchange',
        component: () => import('./view/bank/burExchange'),
        meta: {
            title: ''
        }
    },
    {
        path: '/bank/recharge',
        component: () => import('./view/bank/recharge'),
        meta: {
            title: ''
        }
    },
    {
        path: '/bank/rechargeRecord',
        component: () => import('./view/bank/rechargeRecord'),
        meta: {
            title: ''
        }
    },
    {
        path: '/bank/propose',
        component: () => import('./view/bank/propose'),
        meta: {
            title: ''
        }
    },
    {
        path: '/bank/transfer',
        component: () => import('./view/bank/transfer'),
        meta: {
            title: ''
        }
    },
    {
        path: '/bank/transferRecord',
        component: () => import('./view/bank/transferRecord'),
        meta: {
            title: ''
        }
    },
    {
        path: '/bank/proposeRecord',
        component: () => import('./view/bank/proposeRecord'),
        meta: {
            title: ''
        }
    },
    {
        path: '/bank/binding',
        component: () => import('./view/bank/binding'),
        meta: {
            title: ''
        }
    },
    {
        path: '/bank/share',
        component: () => import('./view/bank/share'),
        meta: {
            title: ''
        }
    },
    {
        path: '/bank/shareImage',
        component: () => import('./view/bank/shareImage'),
        meta: {
            title: ''
        }
    },
    {
        path: '/bank/record',
        component: () => import('./view/bank/record'),
        meta: {
            title: ''
        }
    },
    {
        path: '/bank/invite',
        component: () => import('./view/bank/invite'),
        meta: {
            title: ''
        }
    },
    {
        path: '/bank/inviteRecord',
        component: () => import('./view/bank/inviteRecord'),
        meta: {
            title: ''
        }
    },
    {
        path: '/bank/storage',
        component: () => import('./view/bank/storage'),
        meta: {
            title: ''
        }
    },
    {
        path: '/bank/storageCurrent',
        component: () => import('./view/bank/storageCurrent'),
        meta: {
            title: ''
        }
    },
    {
        path: '/bank/storagePeriodic',
        component: () => import('./view/bank/storagePeriodic'),
        meta: {
            title: ''
        }
    },
    {
        path: '/bank/storagePeriodicOrder',
        component: () => import('./view/bank/storagePeriodicOrder'),
        meta: {
            title: ''
        }
    },
    {
        path: '/notice',
        component: () => import('./view/notice/list'),
        meta: {
            title: ''
        }
    },
    {
        path: '/notice/details/:id',
        component: () => import('./view/notice/details'),
        meta: {
            title: ''
        }
    },
];

// add route path
routes.forEach(route => {
    route.path = route.path || '/' + (route.name || '');
});

const router = new Router({routes});

router.beforeEach((to, from, next) => {
    const title = to.meta && to.meta.title;
    if (title) {
        document.title = title;
    }
    next();
});

export {
    router
};
